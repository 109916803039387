import React from 'react';

import Button from '@splunk/react-ui/Button';

import { stream } from '../links';

import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';

import IngestBase from './IngestBase';

class PipelineContainer extends IngestBase {
    static PATH = '/:tenantId/pipelines/:resourceId/';

    getResource = (tenantId, pipelineId) => this.props.splunkCloud.streams.getPipeline(pipelineId);

    handleActivatePipeline = () => this.props.splunkCloud.streams.activatePipeline(this.props.resourceId, {});

    handleDeactivatePipeline = () =>
        this.props.splunkCloud.streams.deactivatePipeline(this.props.resourceId, {});

    handleDeletePipeline = () => this.props.splunkCloud.streams.deletePipeline(this.props.resourceId);

    renderActions() {
        if (!this.state.resource) {
            return null;
        }

        const { name, status } = this.state.resource;
        const actions = [
            <ConfirmationDialogActionButton
                key="delete"
                handleConfirmedAction={this.handleDeletePipeline}
                label="Delete"
                message={`Are you sure you want to delete the pipeline "${name}"?`}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}/`)
                }
                title={`Delete ${name}`}
            />,
        ];

        if (status === 'ACTIVATED') {
            actions.push(
                <ConfirmationDialogActionButton
                    key="deactivate"
                    handleConfirmedAction={this.handleDeactivatePipeline}
                    label="Deactivate"
                    message={`Are you sure you want to deactivate the pipeline "${name}"?`}
                    onSuccess={() => this.updateResource(this.props.tenantId, this.props.resourceId)}
                    title={`Deactivate ${name}`}
                />
            );
        } else {
            actions.push(
                <ConfirmationDialogActionButton
                    key="activate"
                    handleConfirmedAction={this.handleActivatePipeline}
                    primary
                    label="Activate"
                    message={`Are you sure you want to activate the pipeline "${name}"?`}
                    onSuccess={() => this.updateResource(this.props.tenantId, this.props.resourceId)}
                    title={`Activate ${name}`}
                />
            );
        }
        actions.push(
            <Button
                openInNewContext
                key="edit"                
                to={stream({ tenantId: this.props.tenantId, id: this.props.resourceId })}
                label="Edit"
                appearance="primary"
            />
        );
        return actions;
    }
}

export default PipelineContainer;
