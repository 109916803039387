import React, { useEffect, useState } from 'react';

import JSONTree from '@splunk/react-ui/JSONTree';


import { isMediaTypeImage, isMediaTypeText } from '../mediaTypes';

const Content = ({ mediaType, content }) => {
    const [renderedContent, setRenderedContent] = useState(null);
    useEffect(() => {
        if (isMediaTypeText(mediaType)) {
            try {
                const json = JSON.parse(content);
                if (typeof json ==='string') {
                    setRenderedContent(<pre>{json}</pre>);                        
                } else {
                    setRenderedContent(<JSONTree json={json} expandChildren />);
                }
            } catch {
                setRenderedContent(<pre>{content}</pre>);
            }
        } else if (isMediaTypeImage(mediaType)) {
            setRenderedContent(<img src={content} alt="File Content" />);
        } else {
            setRenderedContent(<p>Content type unsupported for display.</p>)
        }
    }, [mediaType, content]);

    return <div>{renderedContent}</div>;
};



export default Content;