import React from 'react';
import styled from 'styled-components';
import Menu from '@splunk/react-ui/Menu';

import { variables } from '@splunk/themes';

const StyledMenu = styled(Menu)`
    border-radius: 0;

    & > button[aria-selected='true'] {
        border-right: 4px solid ${variables.interactiveColorPrimary};
    }
`;

const SubTabs = ({ selected, tabs, onTabSelected }) => (
    <StyledMenu style={{ marginRight: 1, width: 150, flex: '0 0 auto' }}>
        {tabs.map(({ label, resourceType }) => (
            <Menu.Item
                aria-selected={resourceType === selected}
                key={resourceType}
                onClick={(e) => onTabSelected(e, { tab: resourceType })}
            >
                {label}
            </Menu.Item>
        ))}
    </StyledMenu>
);

export default SubTabs;
