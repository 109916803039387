import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import ChangeLog from '../components/ChangeLog';

const StyledHome = styled.div`
    padding: 16px;
`;

const StyledChangeLog = styled(ChangeLog)`
    background-color: ${variables.backgroundColorSection};
    padding: 16px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
`;

export default () => {
    return (
        <StyledHome>            
            <StyledChangeLog/>
        </StyledHome>
    );
};
