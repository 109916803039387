import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';
import Message from '@splunk/react-ui/Message';

const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const handleClick = (history, to) => (event) => {
    if (!isModifiedEvent(event) && history) {
        event.preventDefault();
        history.push(to);
    }
};

// eslint-disable-next-line react/prop-types
const renderResourceButton = (basePath, history) => ({ id, name }) => {
    const to = `${basePath}${encodeURIComponent(id)}/`;
    return (
        <Button key={to} to={to} onClick={handleClick(history, to)} appearance="flat" label={name || id} />
    );
};

const Resources = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px;

    & > * {
        flex: 0 0 auto;
        margin: 8px 8px !important;
    }
`;

const Collection = ({ basePath, resources, resourceType, history }) => (
    <Resources key="resources">
        {resources.map(renderResourceButton(basePath, history))}
        {resources.length === 0 ? <Message>No {resourceType} found.</Message> : null}
    </Resources>
);

Collection.propTypes = {
    basePath: T.string.isRequired,
    resourceType: T.string.isRequired,
    resources: T.array,
    history: T.object,
};

export default Collection;
