import React, { Component } from 'react';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';
import File from '@splunk/react-ui/File';
import Modal from '@splunk/react-ui/Modal';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from '../Error';

const isCSV = /\.csv$/i;

class UploadCSVDialog extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        this.state = {};

        this.fileReader = new FileReader();
    }

    handleAddFiles = (files) => {
        const file = files[0];

        if (file.name.match(isCSV)) {
            if (this.fileReader.readyState === 1) {
                this.fileReader.abort();
            }

            this.fileReader.onload = () => {
                this.setState({ filename: file.name, content: this.fileReader.result });
            };

            this.fileReader.readAsText(file);
        }
    };

    handleRemoveFile = () => {
        if (this.fileReader.readyState === 1) {
            this.fileReader.abort();
        }

        this.setState({ filename: null, content: null });
    };

    render() {
        const { action, saving, error, onCancel, onCreate } = this.props;

        return (
            <Modal open style={{ width: '600px' }}>
                <Modal.Header title={action || 'Upload CSV'} />
                <Modal.Body>
                    <Error error={error} />
                    <File
                        accept=".csv"
                        onRequestAdd={this.handleAddFiles}
                        onRequestRemove={this.handleRemoveFile}
                    >
                        {this.state.filename && <File.Item name={this.state.filename} />}
                    </File>
                </Modal.Body>
                <Modal.Footer>
                    {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                    />
                    <Button
                        disabled={saving || !this.state.content}
                        appearance="primary"
                        onClick={(e) => onCreate(e, this.state)}
                        label={action || 'Upload'}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

UploadCSVDialog.propTypes = {
    saving: T.bool.isRequired,
    action: T.string,
    error: T.object,
    onCreate: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default UploadCSVDialog;
