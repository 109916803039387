import { getCurrentEnvironment, PLAYGROUND, STAGING /*, PRODUCTION*/ } from './environment';

const currentEnvironment = getCurrentEnvironment();

const appHostForEnvironment = (app) => {
    switch (currentEnvironment) {
        // case PRODUCTION:
        //     return `https://${app}.scp.splunk.com`;
        case STAGING:
            return `https://${app}.staging.scp.splunk.com`;
        case PLAYGROUND:
        default:
            return `https://${app}.playground.scp.splunk.com`;
    }
};

export const search = ({ tenantId, sid, earliest, spl }) => {
    const params = [];
    if (sid) {
        params.push(`sid=${encodeURIComponent(sid)}`);
    }
    if (earliest) {
        params.push(`earliest=${encodeURIComponent(earliest)}`);
    }
    if (spl) {
        params.push(`q=${encodeURIComponent(spl)}`);
    }
    return `${appHostForEnvironment('px-search')}/${tenantId}?${params.join('&')}`;
};

export const stream = ({ tenantId, id }) => {
    return `${appHostForEnvironment(
        'dsp'
    )}/${tenantId}/view?version=current&mode=pipeline&pipelineId=${encodeURIComponent(id)}`;
};
