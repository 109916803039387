import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from '@splunk/react-ui/ComboBox';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Switch from '@splunk/react-ui/Switch';
import Text from '@splunk/react-ui/Text';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    modules: PropTypes.arrayOf(PropTypes.string),
    module: PropTypes.string,
    name: PropTypes.string,
};

function CollectionForm({ module, modules, name, lookup, onChange }) {
    return [
        <ControlGroup key="module" label="Module">
            <ComboBox name="module" value={module} placeholder="Pick or create a module" onChange={onChange}>
                {modules
                    ? modules.map((moduleName) => <ComboBox.Option key={moduleName} value={moduleName} />)
                    : null}
            </ComboBox>
        </ControlGroup>,
        <ControlGroup key="name" label="Name">
            <Text name="name" value={name} placeholder="Name of the dataset" onChange={onChange} />
        </ControlGroup>,
        <ControlGroup key="lookup" label="Create Lookup">
            <Switch
                name="name"
                value="lookup"
                selected={lookup}
                onClick={(e) => onChange(e, { name: 'lookup', value: !lookup })}
            />
        </ControlGroup>,
    ];
}

CollectionForm.propTypes = propTypes;
export default CollectionForm;
