import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';

import DL from '@splunk/react-ui/DefinitionList';

const StyledMetadata = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 10px;
    & > dl {
        padding: 10px;
    }
`;

export const Metadata = ({ data }) => {
    return (
        <StyledMetadata>
            <DL>
                <DL.Term>ID</DL.Term>
                <DL.Description>{data.id}</DL.Description>
                <DL.Term>Owner</DL.Term>
                <DL.Description>{data.owner}</DL.Description>
                <DL.Term>Version</DL.Term>
                <DL.Description>{data.version}</DL.Description>
            </DL>
            <DL>
                <DL.Term>Created Date</DL.Term>
                <DL.Description>{data.created}</DL.Description>
                <DL.Term>Created By</DL.Term>
                <DL.Description>{data.createdby}</DL.Description>
                <DL.Term>App Created By</DL.Term>
                <DL.Description>{data.appclientidcreatedby}</DL.Description>
            </DL>
            <DL>
                <DL.Term>Modified Date</DL.Term>
                <DL.Description>{data.modified}</DL.Description>
                <DL.Term>Modified By</DL.Term>
                <DL.Description>{data.modifiedby}</DL.Description>
                <DL.Term>App Modified By</DL.Term>
                <DL.Description>{data.appclientidmodifiedby}</DL.Description>
            </DL>
        </StyledMetadata>
    );
};

export const omitMetadata = (data) =>
    omit(data, [
        'id',
        'owner',
        'version',
        'created',
        'createdby',
        'appclientidcreatedby',
        'modified',
        'modifiedby',
        'appclientidmodifiedby',
    ]);

export default Metadata;
