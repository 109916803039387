import React from 'react';
import T from 'prop-types';

import CreateDialog from './CreateDialog';
import DialogActionButton from './DialogActionButton';

const CreateDialogActionButton = ({ extra, handleCreate, label, onSuccess, primary, resourceType }) => (
    <DialogActionButton label={label} primary={primary} action={handleCreate} onSuccess={onSuccess}>
        {({ handleAction, error, saving }) => (
            <CreateDialog
                saving={saving}
                action={label}
                extra={extra}
                resourceType={resourceType}
                error={error}
                onCreate={handleAction}
                onCancel={handleAction}
            />
        )}
    </DialogActionButton>
);

CreateDialogActionButton.propTypes = {
    extra: T.node,
    label: T.string.isRequired,
    resourceType: T.string.isRequired,
    handleCreate: T.func.isRequired,
    onSuccess: T.func,
    primary: T.bool,    
};

export default CreateDialogActionButton;
