import React, { Component } from 'react';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Modal from '@splunk/react-ui/Modal';
import Text from '@splunk/react-ui/Text';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from '../Error';
import { FieldType, DataType } from '../Fields';

const createField = ({ name, fieldtype, datatype }) => ({ name, fieldtype, datatype });

class CreateFieldDialog extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        this.keys = [];

        this.state = {
            name: '',
            fieldtype: 'UNKNOWN',
            datatype: 'STRING',
        };
    }

    handleChange = (e, { name, value }) => {
        this.setState((prev) => ({ ...prev, [name]: value }));
    };

    render() {
        const { action, saving, error, onCancel, onCreate } = this.props;

        return (
            <Modal open style={{ width: '600px' }}>
                <Modal.Header title={action || 'Create Field'} />
                <Modal.Body>
                    <Error error={error} />
                    <div>
                        <ControlGroup label="Name">
                            <Text name="name" value={this.state.name} onChange={this.handleChange} />
                        </ControlGroup>
                        <ControlGroup label="Data Type">
                            <DataType datatype={this.state.datatype} onChange={this.handleChange} />
                        </ControlGroup>
                        <ControlGroup label="Field Role">
                            <FieldType fieldtype={this.state.fieldtype} onChange={this.handleChange} />
                        </ControlGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                    />
                    <Button
                        disabled={saving || !this.state.name}
                        appearance="primary"
                        onClick={(e) => onCreate(e, { field: createField(this.state) })}
                        label={action || 'Create'}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

CreateFieldDialog.propTypes = {
    saving: T.bool.isRequired,
    action: T.string,
    error: T.object,
    onCreate: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default CreateFieldDialog;
