import React from 'react';

import Collection from '../components/Collection';

import PermissionsBase from './PermissionsBase';

const s2o = (id) => ({ id });

class RolesContainer extends PermissionsBase {
    static PATH = '/:tenantId/roles/';
    title = 'Roles';

    getResource = (tenantId) =>
        this.props.splunkCloud.identity.listRoles().then((response) => response.map(s2o));

    createResource = (tenantId, resourceId, data) =>
        this.props.splunkCloud.identity.createRole({ name: data.name, permissions: [] });

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/roles/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default RolesContainer;
