import React from 'react';
import T from 'prop-types';

import H from '@splunk/react-ui/Heading';
import Text from '@splunk/react-ui/Text';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import StaticContent from '@splunk/react-ui/StaticContent';

import AppKind from '../AppKind';

const dateTime = (iso) => {
    const d = new Date(iso);
    return `${d.toDateString()} ${d.toLocaleTimeString()}`;
};

const formStyle = { padding: 8 };

const tenantsStyle = {
    display: 'flex',
    flexWrap: 'wrap',
};

const tenantStyle = {
    margin: 4,
    padding: 4,
    borderRadius: 4,
    backgroundColor: '#1d1d1d',
    border: '1px solid #999',
};

export const App = ({ app, onPropertyChanged }) => (
    <div style={formStyle}>
        <ControlGroup label="Title">
            <Text value={app.title} name="title" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="Client ID">
            <StaticContent>{app.clientId}</StaticContent>
        </ControlGroup>
        <AppKind value={app.kind} disabled />
        {app.createdBy ? (
            <ControlGroup label="Creator">
                <StaticContent>{app.createdBy}</StaticContent>
            </ControlGroup>
        ) : null}
        {app.createdAt ? (
            <ControlGroup label="Created">
                <StaticContent>{dateTime(app.createdAt)}</StaticContent>
            </ControlGroup>
        ) : null}
        <ControlGroup label="Description">
            <Text multiline value={app.description} name="description" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="Login URL">
            <Text value={app.loginUrl} name="loginUrl" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="Logo URL">
            <Text value={app.logoUrl} name="logoUrl" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="Redirect URLs">
            <Text
                multiline
                value={app.redirectUrls ? app.redirectUrls.join('\n') : ''}
                name="redirectUrls"
                onChange={onPropertyChanged}
            />
        </ControlGroup>
        <ControlGroup label="Setup URL">
            <Text value={app.setupUrl} name="setupUrl" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="Web Hook URL">
            <Text value={app.webhookUrl} name="webhookUrl" onChange={onPropertyChanged} />
        </ControlGroup>
        <ControlGroup label="App Principal Permissions">
            <Text
                value={app.appPrincipalPermissions ? app.appPrincipalPermissions.join('\n') : ''}
                name="appPrincipalPermissions"
                onChange={onPropertyChanged}
                multiline
            />
        </ControlGroup>
        <ControlGroup label="User Permissions Filter">
            <Text
                value={app.userPermissionsFilter ? app.userPermissionsFilter.join('\n') : ''}
                name="userPermissionsFilter"
                onChange={onPropertyChanged}
                multiline
            />
        </ControlGroup>
        <H level={2}>Tenants</H>
        <div style={tenantsStyle}>
            {app.tenants.map((tenant) => (
                <span style={tenantStyle} key={tenant}>
                    {tenant}
                </span>
            ))}
        </div>
    </div>
);

const AppType = {
    name: T.string.isRequired,
    clientId: T.string.isRequired,
    createdAt: T.string,
    createdBy: T.string,
    description: T.string.isRequired,
    kind: T.string.isRequired,
    loginUrl: T.string.isRequired,
    logoUrl: T.string.isRequired,
    appPrincipalPermissions: T.arrayOf(T.string).isRequired,
    userPermissionsFilter: T.arrayOf(T.string).isRequired,
    redirectUrls: T.arrayOf(T.string).isRequired,
    setupUrl: T.string.isRequired,
    title: T.string.isRequired,
    webhookUrl: T.string.isRequired,
    tenants: T.arrayOf(T.string).isRequired,
};

App.propTypes = {
    app: T.shape(AppType).isRequired,
    onPropertyChanged: T.func.isRequired,
};

export default App;
