import React from 'react';

import Collection from '../components/Collection';

import IngestBase from './IngestBase';

const passThroughPipeline = `
events=read-splunk-firehose();
write-index(events, literal(""), literal("main"));
`;

class PipelinesContainer extends IngestBase {
    static PATH = '/:tenantId/pipelines/';
    title = 'Pipelines';

    getResource = (tenantId) => this.props.splunkCloud.streams.listPipelines().then(({ items }) => items);

    createResource = (tenantId, resourceId, data) => {
        const client = this.props.splunkCloud;
        return client.streams.compileDSL({ dsl: passThroughPipeline }).then((compiled) =>
            client.streams.createPipeline({
                bypassValidation: true,
                createUserId: 'admin',
                data: compiled,
                description: 'Created from the pass-through pipeline template.',
                name: data.name,
                streamingConfigurationId: null,
            })
        );
    };

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/pipelines/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default PipelinesContainer;
