import IngestBase from './IngestBase';

class TokenContainer extends IngestBase {
    static PATH = '/:tenantId/tokens/:resourceId/';

    getResource = (tenantId, tokenName) => {
        return this.props.splunkCloud.ingest.getCollectorToken(tokenName).then((token) => {
            const {
                name: id,
                created_at: created,
                created_by: createdby,
                last_modified_at: modified,
                last_modified_by: modifiedby,
                ...rest
            } = token;
            return {
                id,
                created,
                createdby,
                owner: createdby,
                modified,
                modifiedby,
                ...rest,
            };
        });
    };
}

export default TokenContainer;
