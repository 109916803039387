import React from 'react';
import PropTypes from 'prop-types';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Text from '@splunk/react-ui/Text';

import ActionKind from './ActionKind';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    kind: PropTypes.string.isRequired,
    name: PropTypes.string,
};

function ActionForm({ kind, name, onChange }) {
    return [
        <ActionKind key="kind" value={kind} onChange={onChange} />,
        <ControlGroup key="name" label="Name">
            <Text name="name" value={name} placeholder="Name for Action" onChange={onChange} />
        </ControlGroup>,
    ];
}

ActionForm.propTypes = propTypes;
export default ActionForm;
