import React, { Component } from 'react';
import T from 'prop-types';

import JSONTree from '@splunk/react-ui/JSONTree';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import CreateDialog from '../components/CreateDialog';
import DeleteDialog from '../components/DeleteDialog';
import ErrorBar from '../components/ErrorBar';
import SubTabs from '../components/SubTabs';

import Header from '../components/Header';
import Metadata, { omitMetadata } from '../components/Metadata';

const singular = (str, s) => (str ? `${str.substr(0, str.length - 1)}` : undefined);

class ResourceContainerBase extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            loading: false,
            saving: false,
            error: null,
            dialogError: null,
            resource: null,
            createDialogOpen: false,
            deleteDialogOpen: false,
        };
    }

    componentDidMount() {
        this.updateResource(this.props.tenantId, this.props.resourceId);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.resourceId !== this.props.resourceId ||
            prevProps.resourcePath !== this.props.resourcePath
        ) {
            this.updateResource(this.props.tenantId, this.props.resourceId);
        }
    }

    onCreate = () => {
        this.setState({ createDialogOpen: true });
    };

    onDelete = () => {
        this.setState({ deleteDialogOpen: true });
    };

    // eslint-disable-next-line class-methods-use-this
    getResource(tenantId, resourceId) {
        return Promise.resolve({ tenantId, resourceId });
    }

    updateResource(tenantId, resourceId) {
        this.setState({ loading: true, resource: null, error: null });
        try {
            this.getResource(tenantId, resourceId).then(
                (resource) => {
                    this.setState((prev) => ({ ...prev, loading: false, resource, error: null }));
                },
                (error) => {
                    this.setState((prev) => ({ ...prev, loading: false, resource: null, error }));
                }
            );
        } catch (error) {
            this.setState((prev) => ({ ...prev, loading: false, resource: null, error }));
        }
    }

    handleSave = () => {
        this.setState({ saving: true });
        try {
            this.saveResource(this.props.tenantId, this.props.resourceId, this.state.resource).then(
                () => {
                    this.setState({ saving: false, error: null });
                },
                (error) => {
                    this.setState({ saving: false, error });
                }
            );
        } catch (error) {
            this.setState({ saving: false, resource: null, error });
        }
    };

    handleDelete = (e, data) => {
        if (data && data.confirmed) {
            this.setState({ saving: false });
            try {
                this.deleteResource(this.props.tenantId, this.props.resourceId).then(
                    () => {
                        this.setState({
                            saving: false,
                            deleteDialogOpen: false,
                            dialogError: null,
                        });
                        this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}/`);
                    },
                    (error) => {
                        this.setState({ saving: false, dialogError: error });
                    }
                );
            } catch (error) {
                this.setState({ saving: false, resource: null, dialogError: error });
            }
        } else {
            this.setState({ deleteDialogOpen: false });
        }
    };

    handleCreate = (e, data) => {
        if (data) {
            this.setState({ saving: true });
            try {
                this.createResource(this.props.tenantId, this.props.resourceId, data).then(
                    () => {
                        this.setState({ createDialogOpen: false, saving: false, error: null });
                        this.updateResource(this.props.tenantId, this.props.resourceId);
                    },
                    (error) => {
                        this.setState({ saving: false, dialogError: error });
                    }
                );
            } catch (error) {
                this.setState({ saving: false, resource: null, dialogError: error });
            }
        } else {
            this.setState({ createDialogOpen: false, dialogError: null });
        }
    };

    handleError = (error) => {
        this.setState((prev) => ({ ...prev, error }));
    };

    // eslint-disable-next-line class-methods-use-this
    renderResource(resource) {
        if (Array.isArray(resource)) {
            return <JSONTree json={resource} expandChildren />;
        }

        return (
            <>
                <Metadata data={resource} />
                <JSONTree json={omitMetadata(resource)} expandChildren />
            </>
        );
    }

    renderCreateDialog() {
        return (
            <CreateDialog
                open={this.state.createDialogOpen}
                resourceType={this.singular || singular(this.title)}
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
            />
        );
    }

    renderDeleteDialog(resource) {
        return (
            <DeleteDialog
                open={this.state.deleteDialogOpen}
                resourceType={this.props.resourceType}
                resourceId={(resource && resource.name) || this.props.resourceId}
                error={this.state.dialogError}
                onDelete={this.handleDelete}
                onCancel={this.handleDelete}
                saving={this.state.saving}
            />
        );
    }

    navigate(path) {
        this.props.history.push(`/${this.props.tenantId}/${path}`);
    }

    // eslint-disable-next-line
    renderActions() {
        return null;
    }

    subTabs() {
        return null;
    }

    render() {
        const { resourceId } = this.props;
        const { resource, error, loading } = this.state;
        const actions = this.renderActions();
        const tabs = this.subTabs();
        const countLabel = Array.isArray(this.state.resource) ? ` (${this.state.resource.length})` : '';
        return (
            <div style={{ display: 'flex', height: '100%' }}>
                {tabs && tabs.length && (
                    <SubTabs
                        tabs={tabs}
                        selected={this.props.resourceType}
                        onTabSelected={(e, { tab }) => this.navigate(tab)}
                    />
                )}
                <div style={{ overflow: 'scroll', flex: '1 1 auto' }}>
                    <Header
                        title={
                            (resource && resource.name) ||
                            (this.title && `${this.title}${countLabel}`) ||
                            resourceId
                        }
                        singular={this.singular || singular(this.title, this.singular)}
                        onCreate={!actions && this.createResource ? this.onCreate : null}
                        onDelete={
                            !actions && !this.state.loading && this.deleteResource ? this.onDelete : null
                        }
                        onSave={!actions && this.saveResource ? this.handleSave : null}
                    >
                        {actions}
                    </Header>
                    {loading ? <WaitSpinner size="medium" /> : null}
                    <ErrorBar
                        error={error}
                        onRetry={() => {
                            this.updateResource(this.props.tenantId, this.props.resourceId);
                        }}
                    />
                    {resource ? this.renderResource(resource) : null}
                    {this.state.createDialogOpen ? this.renderCreateDialog(resource) : null}
                    {this.state.deleteDialogOpen ? this.renderDeleteDialog(resource) : null}
                </div>
            </div>
        );
    }
}

ResourceContainerBase.propTypes = {
    history: T.object.isRequired,
    tenantId: T.string.isRequired,
    resourceType: T.string,
    resourcePath: T.string,
    resourceId: T.string,
};

export default ResourceContainerBase;
