import { useMemo } from 'react';

import { SplunkCloud } from '@splunkdev/cloud-sdk';

import { useAuth } from './AuthContext';
import { useTenant } from './TenantContext';

const useSplunkCloud = () => {
    const tenant = useTenant();
    const { api, app, tokenSource, error } = useAuth();

    const splunkCloud = useMemo(() => {
        if (error) {
            return null;
        }
        return new SplunkCloud({
            urls: { api, app },
            tokenSource,
            defaultTenant: tenant,
        });
    }, [api, app, tokenSource, tenant, error]);

    return splunkCloud;
};

export default useSplunkCloud;
