import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from '@splunk/react-ui/ComboBox';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Message from '@splunk/react-ui/Message';
import Select from '@splunk/react-ui/Select';
import Text from '@splunk/react-ui/Text';

const propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired,
    success: PropTypes.bool,
    module: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const datasetKinds = [
    { name: 'index', label: 'Event' },
    { name: 'metric', label: 'Metric' },
];

function Create({ error, kind, module, modules, name, search, onChange, success }) {
    return [
        error && (
            <Message key="error-message" fill type="error">
                Failed to create dataset: {error}
            </Message>
        ),
        success && (
            <Message key="success-message" fill type="success">
                Dataset successfully created
            </Message>
        ),
        <ControlGroup key="name" label="Name">
            <Text name="name" value={name} placeholder={`Name of the ${kind}`} onChange={onChange} />
        </ControlGroup>,
        <ControlGroup key="module" label="Namespace">
            <ComboBox name="module" value={module} placeholder="Pick or create a namespace" onChange={onChange}>
                {modules.map((moduleName) => (
                    <ComboBox.Option key={moduleName} value={moduleName} />
                ))}
            </ComboBox>
        </ControlGroup>,
        kind !== 'view' && kind !== 'module' && <ControlGroup key="kind" label="Kind">
            <Select name="kind" value={kind} onChange={onChange}>
                {datasetKinds.map((datasetKind) => (
                    <Select.Option
                        key={datasetKind.name}
                        label={datasetKind.label}
                        value={datasetKind.name}
                    />
                ))}
            </Select>
        </ControlGroup>,
        (kind === 'view' || kind === 'module') && <ControlGroup key="search" label="SPL">
                <Text name="search" value={search} placeholder={`SPL for the ${kind}`} onChange={onChange} />
            </ControlGroup>,

    ];
}

Create.propTypes = propTypes;
export default Create;
