import React from 'react';
import T from 'prop-types';

import ActionButton from '../DialogActionButton';
import CreateRecordDialog from './index';

const label = 'Create Record';

const CreateRecordDialogActionButton = ({ handleCreateRecordAction, onSuccess }) => (
    <ActionButton
        key="CreateRecordButton"
        primary
        label={label}
        action={handleCreateRecordAction}
        onSuccess={onSuccess}
    >
        {({ handleAction, error, saving }) => (
            <CreateRecordDialog
                key="CreateRecordDialog"
                saving={saving}
                action={label}
                error={error}
                onCreate={handleAction}
                onCancel={handleAction}
            />
        )}
    </ActionButton>
);

CreateRecordDialogActionButton.propTypes = {
    handleCreateRecordAction: T.func.isRequired,
    onSuccess: T.func,
};

export default CreateRecordDialogActionButton;
