import React from 'react';

import Role from '../components/Role';

import PermissionsBase from './PermissionsBase';

class RoleContainer extends PermissionsBase {
    static PATH = '/:tenantId/roles/:resourceId/';
    getResource = (tenantId, roleId) => {
        const client = this.props.splunkCloud;
        return Promise.all([
            client.identity.getRole(roleId),
            client.identity.listRolePermissions(roleId),
        ]).then(([role, permissions]) => ({ ...role, permissions }));
    };

    deleteResource = (tenantId, roleId) => this.props.splunkCloud.identity.deleteRole(roleId);

    handleAddPermission = (permissionValue) => {
        this.props.splunkCloud.identity.addRolePermission(this.props.resourceId, permissionValue).then(
            () => {
                this.setState({
                    dialogError: null,
                    resource: {
                        ...this.state.resource,
                        permissions: [permissionValue, ...this.state.resource.permissions],
                    },
                });
            },
            (error) => {
                this.setState({ dialogError: error });
            }
        );
    };

    handleRemovePermission = (permissionValue) => {
        this.props.splunkCloud.identity.removeRolePermission(this.props.resourceId, permissionValue).then(
            () => {
                this.setState({
                    dialogError: null,
                    resource: {
                        ...this.state.resource,
                        permissions: this.state.resource.permissions.filter(
                            (permission) => permission !== permissionValue
                        ),
                    },
                });
            },
            (error) => {
                this.setState({ dialogError: error });
            }
        );
    };

    renderResource = (role) => (
        <Role
            role={role.name}
            permissions={role.permissions}
            onAddPermission={this.handleAddPermission}
            onRemovePermission={this.handleRemovePermission}
            error={this.state.dialogError}
        />
    );
}

export default RoleContainer;
