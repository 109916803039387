import React, { useMemo } from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';

import Nav from '@splunk/react-scs-nav';
import NavProvider from '@splunk/react-scs-nav/NavProvider';
import BoxChecked from '@splunk/react-icons/BoxChecked';
import BoxUnchecked from '@splunk/react-icons/BoxUnchecked';

import { useTenant } from './TenantContext';
import { useAuth } from './AuthContext';

import {
    getCurrentEnvironment,
    changeCurrentEnvironment,
    PLAYGROUND,
    STAGING,
    COMPLIANT_PLAYGROUND,
    COMPLIANT_STAGING,
} from './environment';

const currentEnvironment = getCurrentEnvironment();

const tabs = {
    actions: 'Actions',
    apps: 'Apps',
    indexes: 'Data',
    users: 'Users',
    pipelines: 'Ingest',
    rules: 'Rules',
    tenants: 'Tenants',
    files: 'Files',
};

const tabForResourceType = (resourceType) => {
    switch (resourceType) {
        case 'roles':
        case 'groups':
            return 'users';
        case 'collections':
        case 'searches':
        case 'datasets':
        case 'views':
        case 'lookups':
        case 'metadata':
        case 'modules':
            return 'indexes';
        case 'tokens':
            return 'pipelines';
        default:
            return resourceType;
    }
};

const Content = styled.div`
    position: absolute;
    bottom: 0;
    top: 53px;
    left: 0;
    right: 0;
    overflow: scroll;
`;

const NavContainer = ({ appName, children }) => {
    const tenant = useTenant();
    const { api, tokenSource, user } = useAuth();
    const { pathname } = useLocation();
    const path = pathname.split('/');
    const resourceType = path.length > 2 ? path[2] : null;
    const tab = tabForResourceType(resourceType);
    const appNavItems = useMemo(() => {
        return map(tabs, (label, name) => (
            <Nav.AppNavItem selected={name === tab} to={`/${tenant}/${name}`} key={name} label={label} />
        ));
    }, [tenant, tab]);

    return (
        <NavProvider
            apiEndpoint={api}
            authClient={tokenSource}
            tenantName={tenant}
            appName={appName}
            userName={user}
            onRequestLogout={() => tokenSource.logout()}
        >
            <Nav
                appNavItems={appNavItems}
                menuItems={[
                    <Nav.MenuItem
                        onClick={() => changeCurrentEnvironment(PLAYGROUND)}
                        key={PLAYGROUND}
                        icon={
                            currentEnvironment === PLAYGROUND ? (
                                <BoxChecked size="15px" />
                            ) : (
                                <BoxUnchecked size="15px" />
                            )
                        }
                        label="Playground"
                    />,
                    <Nav.MenuItem
                        onClick={() => changeCurrentEnvironment(COMPLIANT_PLAYGROUND)}
                        key={COMPLIANT_PLAYGROUND}
                        icon={
                            currentEnvironment === COMPLIANT_PLAYGROUND ? (
                                <BoxChecked size="15px" />
                            ) : (
                                <BoxUnchecked size="15px" />
                            )
                        }
                        label="Compliant Playground"
                    />,
                    <Nav.MenuItem
                        onClick={() => changeCurrentEnvironment(STAGING)}
                        key={STAGING}
                        icon={
                            currentEnvironment === STAGING ? (
                                <BoxChecked size="15px" />
                            ) : (
                                <BoxUnchecked size="15px" />
                            )
                        }
                        label="Staging"
                    />,
                    <Nav.MenuItem
                        onClick={() => changeCurrentEnvironment(COMPLIANT_STAGING)}
                        key={COMPLIANT_STAGING}
                        icon={
                            currentEnvironment === COMPLIANT_STAGING ? (
                                <BoxChecked size="15px" />
                            ) : (
                                <BoxUnchecked size="15px" />
                            )
                        }
                        label="Compliant Staging"
                    />,
                    // <Nav.MenuItem
                    //     onClick={() => changeCurrentEnvironment(PRODUCTION)}
                    //     key={PRODUCTION}
                    //     icon={currentEnvironment === PRODUCTION ? <BoxChecked size="15px" /> : <BoxUnchecked size="15px" />}
                    //     label="Production"
                    // />,
                ]}
            />
            <Content>{children}</Content>
        </NavProvider>
    );
};

export default NavContainer;
