import React, { useMemo } from 'react';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import useSplunkCloud from './useSplunkCloud';

import Home from './pages/Home';
import Actions from './pages/Actions';
import Action from './pages/Action';
import Apps from './pages/Apps';
import App from './pages/App';
import Collections from './pages/Collections';
import Collection from './pages/Collection';
import Datasets from './pages/Datasets';
import File from './pages/File';
import Files from './pages/Files';
import Indexes from './pages/Indexes';
import Views from './pages/Views';
import Lookups from './pages/Lookups';
import Modules from './pages/Modules';
import Metadata from './pages/Metadata';
import Module from './pages/Module';
import Dataset from './pages/Dataset';
import Groups from './pages/Groups';
import Group from './pages/Group';
import Searches from './pages/Searches';

import Roles from './pages/Roles';
import Role from './pages/Role';
import Rules from './pages/Rules';
import Rule from './pages/Rule';
import Pipelines from './pages/Pipelines';
import Pipeline from './pages/Pipeline';
import Tokens from './pages/Tokens';
import Token from './pages/Token';
import Tenants from './pages/Tenants';
import Tenant from './pages/Tenant';
import Users from './pages/Users';
import User from './pages/User';

const resources = (routes) =>
    routes.map(({ path, render, exact = true }) => (
        <Route exact={exact} key={path} path={path} render={render} />
    ));

const AppRouter = () => {
    const splunkCloud = useSplunkCloud();
    const history = useHistory();
    const { pathname } = useLocation();

    const resourceProps = useMemo(() => {
        const path = pathname.split('/');
        const tenantId = path.length > 1 ? path[1] : null;
        const resourceType = path.length > 2 ? path[2] : null;
        const resourceId = path.length > 3 ? decodeURIComponent(path[3]) : null;
        const resourcePath = path.length > 3 ? path.slice(3, -1).join('/') : null;

        return {
            splunkCloud,
            history,
            tenantId,
            resourceType,
            resourceId,
            resourcePath,
        };
    }, [splunkCloud, history, pathname]);

    return (
        <Switch>
            {resources([
                {
                    path: '/:tenantId/',
                    render: () => <Home {...resourceProps} />,
                },
                {
                    path: '/:tenantId/apps/',
                    render: () => <Apps {...resourceProps} />,
                },
                {
                    path: '/:tenantId/actions/',
                    render: () => <Actions {...resourceProps} />,
                },
                {
                    path: '/:tenantId/actions/:resourceId/',
                    render: () => <Action {...resourceProps} />,
                },
                {
                    path: '/:tenantId/apps/:resourceId/',
                    render: () => <App {...resourceProps} />,
                },
                {
                    path: '/:tenantId/collections/',
                    render: () => <Collections {...resourceProps} />,
                },
                {
                    path: '/:tenantId/collections/:resourceId/',
                    render: () => <Collection {...resourceProps} />,
                },
                {
                    path: '/:tenantId/indexes/',
                    render: () => <Indexes {...resourceProps} />,
                },
                {
                    path: '/:tenantId/datasets/:resourceId/',
                    render: () => <Dataset {...resourceProps} />,
                },
                {
                    path: '/:tenantId/groups/',
                    render: () => <Groups {...resourceProps} />,
                },
                {
                    path: '/:tenantId/groups/:resourceId/',
                    render: () => <Group {...resourceProps} />,
                },
                {
                    path: '/:tenantId/roles/',
                    render: () => <Roles {...resourceProps} />,
                },
                {
                    path: '/:tenantId/roles/:resourceId/',
                    render: () => <Role {...resourceProps} />,
                },
                {
                    path: '/:tenantId/rules/',
                    render: () => <Rules {...resourceProps} />,
                },
                {
                    path: '/:tenantId/rules/:resourceId/',
                    render: () => <Rule {...resourceProps} />,
                },
                {
                    path: '/:tenantId/searches/',
                    render: () => <Searches {...resourceProps} />,
                },
                {
                    path: '/:tenantId/pipelines/',
                    render: () => <Pipelines {...resourceProps} />,
                },
                {
                    path: '/:tenantId/pipelines/:resourceId/',
                    render: () => <Pipeline {...resourceProps} />,
                },
                {
                    path: '/:tenantId/tokens/',
                    render: () => <Tokens {...resourceProps} />,
                },
                {
                    path: '/:tenantId/tokens/:resourceId/',
                    render: () => <Token {...resourceProps} />,
                },
                {
                    path: '/:tenantId/tenants/',
                    render: () => <Tenants {...resourceProps} />,
                },
                {
                    path: '/:tenantId/tenants/:resourceId/',
                    render: () => <Tenant {...resourceProps} />,
                },
                {
                    path: '/:tenantId/users/',
                    render: () => <Users {...resourceProps} />,
                },
                {
                    path: '/:tenantId/users/:resourceId/',
                    render: () => <User {...resourceProps} />,
                },
                {
                    path: '/:tenantId/datasets/',
                    render: () => <Datasets {...resourceProps} />,
                },
                {
                    path: '/:tenantId/lookups/',
                    render: () => <Lookups {...resourceProps} />,
                },
                {
                    path: '/:tenantId/modules/',
                    render: () => <Modules {...resourceProps} />,
                },
                {
                    path: '/:tenantId/metadata/',
                    render: () => <Metadata {...resourceProps} />,
                },
                {
                    path: '/:tenantId/modules/:resourceId/',
                    render: () => <Module {...resourceProps} />,
                },
                {
                    path: '/:tenantId/views/',
                    render: () => <Views {...resourceProps} />,
                },
                {
                    path: '/:tenantId/files/:resourceId/content',
                    render: () => <File {...resourceProps} />,
                },
                {
                    path: '/:tenantId/files/:resourceId/',
                    render: () => <Files {...resourceProps} />,
                },
                {
                    path: '/:tenantId/files/',
                    render: () => <Files {...resourceProps} />,
                },
            ])}
        </Switch>
    );
};

export default AppRouter;
