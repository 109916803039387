import React from 'react';

import JSONTree from '@splunk/react-ui/JSONTree';
import Table from '@splunk/react-ui/Table';
import Heading from '@splunk/react-ui/Heading';

import Metadata, { omitMetadata } from '../components/Metadata';

import ResourceContainerBase from './ResourceContainerBase';

function getExpansionRow(row) {
    return (
        <Table.Row key={`${row.id}`}>
            <Table.Cell style={{ borderTop: 'none' }} colSpan={3}>
                <Metadata data={row} />
                <JSONTree json={omitMetadata(row)} expandChildren />
            </Table.Cell>
        </Table.Row>
    );
}

class RulesContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/rules/';
    title = 'Rules';

    getResource = (tenantId, resourceId) => this.props.splunkCloud.catalog.getRuleById(resourceId);

    renderResource() {
        return (
            <>
                <Metadata data={this.state.resource} />
                <Heading level={3}>{this.state.resource.match}</Heading>
                <Table rowExpansion="single">
                    <Table.Head>
                        <Table.HeadCell>Kind</Table.HeadCell>
                        <Table.HeadCell>Field</Table.HeadCell>
                        <Table.HeadCell>Content</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                        {this.state.resource.actions.map((row) => (
                            <Table.Row key={row.id} expansionRow={getExpansionRow(row)}>
                                <Table.Cell>{row.kind}</Table.Cell>
                                <Table.Cell>{row.field}</Table.Cell>
                                <Table.Cell>
                                    {row.alias || row.expression || row.pattern || row.mode}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
    }
}

export default RulesContainer;
