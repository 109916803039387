import React from 'react';

import { createBrowserHistory } from 'history';
import queryString from 'query-string';

import { NavigationProvider, isInternalLink } from '@splunk/react-ui/Clickable';

import Auth from './Auth';
import Theme from './Theme';

import { Redirect, Route, Router, Switch } from 'react-router-dom';

import AppRoutes from './AppRouter';
import TenantContainer from './TenantContainer';

import { getCurrentEnvironment } from './environment';
import sscConfigs from './config/ssc.json';

const currentEnvironment = getCurrentEnvironment();
const config = sscConfigs[currentEnvironment];

const history = createBrowserHistory();

const onNavigateLink = (e, { to, openInNewContext }) => {
    if (!openInNewContext && isInternalLink(to)) {
        e.preventDefault();
        history.push(to);
    }
};

const App = () => (
    <Theme>
        <Router history={history}>
            <Switch>
                <Route
                    path="/r/switchApp"
                    render={(props) => (
                        <Redirect to={`/${queryString.parse(props.location.search).tenantName}/`} />
                    )}
                />
                <Route>
                    <Auth history={history} config={config}>
                        <NavigationProvider onClick={onNavigateLink}>
                            <Switch>
                                <Route path="/:tenantName">
                                    <TenantContainer appName={config.appName}>
                                        <AppRoutes />
                                    </TenantContainer>
                                </Route>
                                <Route path="/">
                                    <TenantContainer />
                                </Route>
                            </Switch>
                        </NavigationProvider>
                    </Auth>
                </Route>
            </Switch>
        </Router>
    </Theme>
);

export default App;
