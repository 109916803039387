import React, { Component } from 'react';
import T from 'prop-types';
import Button from '@splunk/react-ui/Button';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Modal from '@splunk/react-ui/Modal';
import Text from '@splunk/react-ui/Text';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from './Error';

class SubscriptionDialog extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        this.state = {
            value: '',
        };
    }

    handleChange = (e, { value }) => {
        this.setState({ value });
    };

    render() {
        const { saving, error, onCancel, onSubscribe } = this.props;
        return (
            <Modal open style={{ width: '600px' }}>
                <Modal.Header title="Subscribe to App" />
                <Modal.Body>
                    <Error error={error} />
                    <ControlGroup label="App Name">
                        <Text canClear autoFocus value={this.state.value} onChange={this.handleChange} />
                    </ControlGroup>
                </Modal.Body>
                <Modal.Footer>
                    {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                    />
                    <Button
                        disabled={saving || !this.state.value}
                        appearance="primary"
                        onClick={(e) => onSubscribe(e, { name: this.state.value })}
                        label="Subscribe"
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

SubscriptionDialog.propTypes = {
    saving: T.bool.isRequired,
    error: T.object,
    onSubscribe: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default SubscriptionDialog;
