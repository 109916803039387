import React from 'react';

import Button from '@splunk/react-ui/Button';
import Link from '@splunk/react-ui/Link';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import Collection from '../components/Collection';

import PermissionsBase from './PermissionsBase';

import CreateDialogActionButton from '../components/CreateDialogActionButton';
import Error from '../components/Error';

const processResponse = response => {
    const nextLink = response.body.nextLink;
    const users = response.body.items.map(user => ({
        id: user.name,
        name: user.profile && user.profile.fullName,
    }));

    return { users, nextLink };
};

const parseLink = nextLink => {
    const url = new URL(`${window.location.origin}${nextLink}`);
    const path = url.pathname;
    const query = Object.fromEntries(url.searchParams);
    return { path, query };
};

class UsersContainer extends PermissionsBase {
    static PATH = '/:tenantId/users/';
    title = 'Users';

    getResource = tenantId => {
        return this.props.splunkCloud.client.get('api', `/${tenantId}/identity/v3/members`).then(response => {
            const { users, nextLink } = processResponse(response);
            this.setState(prev => ({ ...prev, nextLink }));
            return users;
        });
    };

    createResource = data => {
        return this.props.splunkCloud.identity.addMember({ name: data.name }).then(result => {
            setTimeout(() => {
                this.props.history.push(`/${this.props.tenantId}/users/${encodeURIComponent(data.name)}`);
            }, 0);
            return result;
        });
    };

    renderActions() {
        const consoleLink = `https://console.playground.scp.splunk.com/${this.props.tenantId}/users`;
        return (
            <CreateDialogActionButton
                label="Add User"
                resourceType="User"
                primary
                handleCreate={this.createResource}
                onSuccess={() => this.updateResource(this.props.tenantId, this.props.resourceId)}
                extra={
                    <Link to={consoleLink} openInNewContext>
                        {consoleLink}
                    </Link>
                }
            />
        );
    }

    nextPage = () => {
        this.setState(prev => ({ ...prev, pageLoading: true }));
        const { path, query } = parseLink(this.state.nextLink);
        this.props.splunkCloud.client.get('api', path, { query }).then(
            response => {
                const { users, nextLink } = processResponse(response);
                this.setState(prev => ({
                    ...prev,
                    nextLink,
                    pageLoading: false,
                    pageError: null,
                    resource: prev.resource.concat(users),
                }));
            },
            pageError => {
                this.setState(prev => ({ ...prev, pageError, pageLoading: false }));
            }
        );
    };

    renderResource() {
        const { nextLink, pageError, pageLoading } = this.state;
        const collection = (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/users/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );

        if (!nextLink) {
            return collection;
        }
        return (
            <>
                {collection}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 16 }}>
                    <Error error={pageError} />
                    {pageLoading ? (
                        <WaitSpinner size="medium" />
                    ) : (
                        <Button onClick={this.nextPage}>Load more...</Button>
                    )}
                </div>
            </>
        );
    }
}

export default UsersContainer;
