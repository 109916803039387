import React from 'react';
import { uniqBy } from 'lodash';

import Card from '@splunk/react-ui/Card';

import { search } from '../links';

import DatasetsBase from './DatasetsBase';

const preStyle = {
    padding: '0 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.8em',
};

class SearchesContainer extends DatasetsBase {
    static PATH = '/:tenantId/jobs/';
    title = 'Recent Searches';

    getResource = (tenantId) => this.props.splunkCloud.catalog.listDatasets({ filter: 'kind="job"' });

    renderResource() {
        return (
            <div style={{ padding: '0 20px' }}>
                {this.state.resource
                    ? uniqBy(this.state.resource, 'query').map((dataset) => (
                          <Card
                              key={dataset.resourcename}
                              openInNewContext
                              to={search({ tenantId: this.props.tenantId, sid: dataset.sid })}
                              style={{ maxWidth: '100%', margin: '10px 0' }}
                          >
                              <pre style={preStyle}>{dataset.query}</pre>
                          </Card>
                      ))
                    : null}
            </div>
        );
    }
}

export default SearchesContainer;
