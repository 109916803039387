import React from 'react';
import T from 'prop-types';

import Text from '@splunk/react-ui/Text';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import StaticContent from '@splunk/react-ui/StaticContent';

const formStyle = { padding: 8 };

const Action = ({ action, onPropertyChanged }) => (
    <div style={formStyle}>
        <ControlGroup label="Name">
            <StaticContent>{action.name}</StaticContent>
        </ControlGroup>
        <ControlGroup label="Kind">
            <StaticContent>{action.kind}</StaticContent>
        </ControlGroup>
        {action.kind === 'webhook'
            ? [
                  <ControlGroup label="Webhook URL" key="url">
                      <Text value={action.webhookUrl} name="webhookUrl" onChange={onPropertyChanged} />
                  </ControlGroup>,
                  <ControlGroup label="Message" key="message">
                      <Text multiline value={action.message} name="message" onChange={onPropertyChanged} />
                  </ControlGroup>,
              ]
            : [
                  <ControlGroup label="Email Addresses" key="addresses">
                      <Text
                          multiline
                          value={action.addresses ? action.addresses.join('\n') : ''}
                          name="addresses"
                          onChange={onPropertyChanged}
                      />
                  </ControlGroup>,
                  <ControlGroup label="Subject" key="subject">
                      <Text value={action.subject} name="subject" onChange={onPropertyChanged} />
                  </ControlGroup>,
                  <ControlGroup label="Body" key="body">
                      <Text multiline value={action.body} name="body" onChange={onPropertyChanged} />
                  </ControlGroup>,
              ]}
    </div>
);

const ActionType = {
    name: T.string.isRequired,
    kind: T.string.isRequired,
    webhookUrl: T.string,
    message: T.string,
    addresses: T.arrayOf(T.string),
    subject: T.string,
    body: T.string,
};

Action.propTypes = {
    action: T.shape(ActionType).isRequired,
    onPropertyChanged: T.func.isRequired,
};

export default Action;
