import React from 'react';
import styled from 'styled-components';

import DL from '@splunk/react-ui/DefinitionList';

import DatasetsBase from './DatasetsBase';
import EditSearch from './EditSearch';


const Container = styled.div`
    & > dl {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class ModuleContainer extends DatasetsBase {
    static PATH = '/:tenantId/modules/:resourceId/';
    getResource = (tenantId, resourceId) => {
        return this.props.splunkCloud.client
            .get('api', `/${tenantId}/search/v3alpha1/spl2-modules/${resourceId}`).then((response) => response.body);
    };

    deleteResource = () => {        
        return this.props.splunkCloud.client
            .delete('api', `/${this.props.tenantId}/search/v3alpha1/spl2-modules/${this.props.resourceId}`);
    };

    handleUpdateModule = (definition) => {
        const { name, namespace } = this.state.resource;
        return this.props.splunkCloud.client
            .put('api', `/${this.props.tenantId}/search/v3alpha1/spl2-modules/${this.props.resourceId}`, {
                name, namespace, definition
            });
    };

    renderResource() {
        return (
            <Container>
                <DL>
                    <DL.Term>Created Date</DL.Term>
                    <DL.Description>{this.state.resource.createdAt}</DL.Description>
                    <DL.Term>Created By</DL.Term>
                    <DL.Description>{this.state.resource.createdBy}</DL.Description>
                </DL>
                <EditSearch search={this.state.resource.definition} onUpdateSearch={this.handleUpdateModule} />
            </Container>
        );
    }    
}

export default ModuleContainer;
