import React from 'react';
import styled from 'styled-components';

import ChevronLeft from '@splunk/react-icons/ChevronLeft';
import ChevronRight from '@splunk/react-icons/ChevronRight';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Button from '@splunk/react-ui/Button';
import Link from '@splunk/react-ui/Link';
import Table from '@splunk/react-ui/Table';
import Text from '@splunk/react-ui/Text';

import CreateDialogActionButton from '../components/CreateDialogActionButton';
import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';

import ResourceContainerBase from './ResourceContainerBase';

const Metadata = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    & > * {
        margin-right: 20px;
    }
`;
class FilesContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/files/';
    title = ' ';

    getResource = (tenantId, resourceId) => {
        if (resourceId) {
            return this.props.splunkCloud.client
                .get('app', `/${tenantId}/file-drive/v0alpha0/files/${resourceId}`, {
                    query: { parent: resourceId },
                })
                .then(fileResponse => {
                    this.title = fileResponse.body.name;
                    return this.props.splunkCloud.client
                        .get('app', `/${tenantId}/file-drive/v0alpha0/files`, {
                            query: { parent: resourceId },
                        })
                        .then(response => {
                            return { file: fileResponse.body, files: response.body };
                        });
                });
        } else {
            this.title = 'Root';
            return this.props.splunkCloud.client
                .get('app', `/${tenantId}/file-drive/v0alpha0/files`, { query: { parent: 'NULL' } })
                .then(response => {
                    return { files: response.body };
                });
        }
    };

    deleteResource = data => {
        const { tenantId, resourceId } = this.props;
        return this.props.splunkCloud.client.delete(
            'app',
            `/${tenantId}/file-drive/v0alpha0/files/${resourceId}`
        );
    };

    saveResource = data => {
        const { tenantId, resourceId } = this.props;
        const { file } = this.state.resource;
        const changes = {
            ...file,
            name: file.name,
            parent: file.parent,
            mediaType: file.mediaType,
        };
        return this.props.splunkCloud.client.patch(
            'app',
            `/${tenantId}/file-drive/v0alpha0/files/${resourceId}`,
            changes
        );
    };

    createResource = data => {
        const { file } = this.state.resource;
        if (!file || !file.id) {
            return Promise.reject(new Error("You can't create files from the root."));
        }
        const newFile = {
            name: data.name,
            parent: file.id,
        };
        const { tenantId } = this.props;
        return this.props.splunkCloud.client
            .post('app', `/${tenantId}/file-drive/v0alpha0/files`, newFile)
            .then(response => response.body);
    };

    fileCreated = file => {
        this.setState(prev => {
            return { ...prev, resource: { ...prev.resource, files: [file, ...prev.resource.files] } };
        });
    };

    renderActions() {
        const actions = [];

        if (this.state.resource && this.state.resource.file) {
            const { name, id } = this.state.resource.file;
            if (this.state.resource.file.parent) {
                actions.push(
                    <ConfirmationDialogActionButton
                        key="delete"
                        label="Delete"
                        title={`Delete ${name}`}
                        message={`Delete the file "${name}" with id ${id}?`}
                        handleConfirmedAction={this.deleteResource}
                        onSuccess={() =>
                            this.props.history.push(
                                `/${this.props.tenantId}/${this.props.resourceType}/${this.state.resource.file.parent}`
                            )
                        }
                    />
                );
            }

            actions.push(
                <ConfirmationDialogActionButton
                    key="save"
                    label="Save"
                    title={`Save ${name}`}
                    message={`Save the file "${name}" with id ${id}?`}
                    handleConfirmedAction={this.saveResource}
                    onSuccess={() => undefined}
                />
            );

            actions.push(
                <CreateDialogActionButton
                    key="create"
                    label="Create File"
                    resourceType="File"
                    primary
                    handleCreate={this.createResource}
                    onSuccess={this.fileCreated}
                    disabled={true}
                />
            );
        }
        return actions;
    }

    handleChangeMetadata = (e, { name, value }) => {
        this.setState(prev => ({
            ...prev,
            resource: { ...prev.resource, file: { ...prev.resource.file, [name]: value } },
        }));
    };

    renderResource() {
        const { tenantId, resourceId } = this.props;
        const { file, files } = this.state.resource;
        return (
            <div style={{ padding: 20 }}>
                {file && (
                    <>
                        <Button
                            size="small"
                            appearance="flat"
                            label="Parent"
                            to={`/${tenantId}/files/${file.parent || ''}`}
                            icon={<ChevronLeft screenReaderText="" hideDefaultTooltip size={1} />}
                        />
                        <Button
                            size="small"
                            appearance="flat"
                            label="Contents"
                            to={`/${tenantId}/files/${resourceId}/content`}
                            icon={<ChevronRight screenReaderText="" hideDefaultTooltip size={1} />}
                        />

                        <Metadata>
                            <ControlGroup label="Name" labelPosition="top">
                                <Text value={file.name} name="name" onChange={this.handleChangeMetadata} />
                            </ControlGroup>
                            <ControlGroup label="Parent" labelPosition="top">
                                <Text
                                    value={file.parent}
                                    name="parent"
                                    onChange={this.handleChangeMetadata}
                                />
                            </ControlGroup>
                            <ControlGroup label="Media Type" labelPosition="top">
                                <Text
                                    value={file.mediaType}
                                    name="mediaType"
                                    onChange={this.handleChangeMetadata}
                                />
                            </ControlGroup>
                            <ControlGroup label="Updated" labelPosition="top">
                                <Text value={file.updatedAt} disabled />
                            </ControlGroup>
                            <ControlGroup label="Created At" labelPosition="top">
                                <Text value={file.createdBy} disabled />
                            </ControlGroup>
                            <ControlGroup label="Created By" labelPosition="top">
                                <Text value={file.createdBy} disabled />
                            </ControlGroup>
                            <ControlGroup label="ID" labelPosition="top">
                                <Text value={file.id} disabled />
                            </ControlGroup>
                        </Metadata>
                    </>
                )}

                {files && files.length > 0 ? (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Type</Table.HeadCell>
                            <Table.HeadCell>Created By</Table.HeadCell>
                            <Table.HeadCell>Updated</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {files.map(child => (
                                <Table.Row key={child.id}>
                                    <Table.Cell>
                                        <Link to={`/${this.props.tenantId}/files/${child.id}`}>
                                            {child.name || '(unamed)'}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{child.mediaType}</Table.Cell>
                                    <Table.Cell>{child.createdBy}</Table.Cell>
                                    <Table.Cell>{child.updatedAt}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                ) : (
                    <p>Contains no files.</p>
                )}
            </div>
        );
    }
}

export default FilesContainer;
