import DatasetsBase from './DatasetsBase';

class LookupsContainer extends DatasetsBase {
    static PATH = '/:tenantId/datasets/';
    title = 'Lookups';

    getResource = (tenantId) =>
        this.props.splunkCloud.catalog.listDatasets({ filter: 'kind="lookup"' });

    renderResource() {
        return this.renderDatasetsList();
    }
}

export default LookupsContainer;
