import React, { Component } from 'react';
import T from 'prop-types';
import { uniq } from 'lodash';

import Button from '@splunk/react-ui/Button';
import Minus from '@splunk/react-icons/Minus';
import Plus from '@splunk/react-icons/Plus';
import FormRows from '@splunk/react-ui/FormRows';
import Modal from '@splunk/react-ui/Modal';
import Text from '@splunk/react-ui/Text';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from '../Error';

const rowStyle = {
    display: 'flex',
    padding: 2,
};

const spanStyle = {
    display: 'inline-block',
    lineHeight: '32px',
    flex: '0 0 30px',
    textAlign: 'center',
};

const valueStyle = {
    flex: '1 0 0',
};

const clearStyle = {
    flexGrow: 0,
};

const validateKeys = (keys) => uniq(keys.filter((key) => key)).length === keys.length;

const createRecord = ({ keys, values }) =>
    keys.reduce((agg, key, index) => ({ ...agg, [key]: values[index] }), {});

class CreateRecordDialog extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        this.keys = [];

        this.state = {
            keys: [''],
            values: [''],
        };
    }

    handleTextChange = (key, isValue) => (e, { value }) => {
        const newValue = {
            ...this.state.values[key],
        };
        if (isValue) {
            newValue.value = value;
        } else {
            newValue.value = key;
        }
        const values = {
            ...this.state.values,
            [key]: newValue,
        };
        this.setState({ values });
    };

    handleRequestAdd = () => {
        this.createRow();
    };

    handleRequestMove = ({ fromIndex, toIndex }) => {
        this.setState({
            items: FormRows.moveRow(fromIndex, toIndex, this.state.items),
        });
    };

    handleRequestRemove = (e, { index }) => {
        this.setState({
            items: FormRows.removeRow(index, this.state.items),
        });
    };

    handleKeyChanged = (index) => (e, { value }) => {
        const keys = [...this.state.keys];
        keys[index] = value;
        this.setState({ keys });
    };

    handleValueChanged = (index) => (e, { value }) => {
        const values = [...this.state.values];
        values[index] = value;
        this.setState({ values });
    };

    handleClearRow = (index) => () => {
        const keys = [...this.state.keys];
        const values = [...this.state.values];
        keys.splice(index, 1);
        values.splice(index, 1);
        this.setState({ keys, values });
    };

    handleCreateRow = (index) => () => {
        const keys = [...this.state.keys];
        const values = [...this.state.values];
        keys.splice(index + 1, 0, '');
        values.splice(index + 1, 0, '');
        this.setState({ keys, values });
    };

    renderRow = (key, index) => {
        const value = this.state.values[index];
        return (
            <div style={rowStyle} key={`row-${index}`}>
                <Text
                    placeholder="key"
                    inline
                    style={valueStyle}
                    describedBy="header-key"
                    value={key}
                    onChange={this.handleKeyChanged(index)}
                />
                <span style={spanStyle}>=</span>
                <Text
                    placeholder="value"
                    inline
                    style={valueStyle}
                    describedBy="header-value"
                    value={value}
                    onChange={this.handleValueChanged(index)}
                />
                <Button
                    disabled={this.state.keys.length < 2}
                    style={clearStyle}
                    appearance="pill"
                    icon={<Minus />}
                    onClick={this.handleClearRow(index)}
                />
                <Button
                    style={clearStyle}
                    appearance="pill"
                    icon={<Plus />}
                    onClick={this.handleCreateRow(index)}
                />
            </div>
        );
    };

    render() {
        const { action, saving, error, onCancel, onCreate } = this.props;

        return (
            <Modal open style={{ width: '600px' }}>
                <Modal.Header title={action || 'Create Record'} />
                <Modal.Body>
                    <Error error={error} />
                    <div>{this.state.keys.map(this.renderRow)}</div>
                </Modal.Body>
                <Modal.Footer>
                    {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                    />
                    <Button
                        disabled={saving || !validateKeys(this.state.keys)}
                        appearance="primary"
                        onClick={(e) => onCreate(e, { record: createRecord(this.state) })}
                        label={action || 'Create'}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

CreateRecordDialog.propTypes = {
    saving: T.bool.isRequired,
    action: T.string,
    error: T.object,
    onCreate: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default CreateRecordDialog;
