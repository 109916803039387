import React from 'react';
import T from 'prop-types';

import DL from '@splunk/react-ui/DefinitionList';
import H from '@splunk/react-ui/Heading';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const dateTime = (iso) => {
    const d = new Date(iso);
    return `${d.toDateString()} ${d.toLocaleTimeString()}`;
};

const Tenant = ({ tenant }) => {
    if (!tenant) {
        return <WaitSpinner size="medium" />;
    }
    return (
        <div>
            <H level={2}>{tenant.name}</H>
            <DL>
                <DL.Term>Status</DL.Term>
                <DL.Description>{tenant.status}</DL.Description>
                <DL.Term>Created By</DL.Term>
                <DL.Description>{tenant.createdBy}</DL.Description>
                <DL.Term>Created At</DL.Term>
                <DL.Description>{dateTime(tenant.createdAt)}</DL.Description>
            </DL>
        </div>
    );
};

Tenant.propTypes = {
    tenant: T.shape({
        createdAt: T.string,
        createdBy: T.string,
        name: T.string,
        status: T.string,
    }),
};

export default Tenant;
