export const isMediaTypeText = mediaType => {

    if (mediaType.startsWith('text/')) {
        return true;
    }

    switch (mediaType) {
        case 'application/json':
        case 'application/vnd.splunk.search-module':
        case 'application/vnd.splunk.search-module-properties':
        case 'application/vnd.splunk.dashboard':
        case 'application/vnd.splunk.pipeline-parameters':
        case 'application/vnd.splunk.pipeline':
            return true;
        default:
            return false;
    }
}

export const isMediaTypeImage = mediaType => {

    if (mediaType.startsWith('image/')) {
        return true;
    }
}