import React from 'react';

import Collection from '../components/Collection';

import PermissionsBase from './PermissionsBase';

const s2o = (id) => ({ id });

const nonIdentityGroup = (group) => !group.startsWith('identity.');
class GroupsContainer extends PermissionsBase {
    static PATH = '/:tenantId/groups/';
    title = 'Groups';

    getResource = (tenantId) =>
        this.props.splunkCloud.identity.listGroups().then((response) => response.filter(nonIdentityGroup).map(s2o));

    createResource = (tenantId, resourceId, data) =>
        this.props.splunkCloud.identity.createGroup({ name: data.name, role: [], members: [] });

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/groups/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default GroupsContainer;
