import React from 'react';

import CreateAppDialog from '../components/CreateAppDialog';
import Collection from '../components/Collection';

import ResourceContainerBase from './ResourceContainerBase';

const createAppFromTemplate = (tenantId, { name, kind }, createApp) => {
    const appTypes = {
        web: {
            kind,
            loginUrl: 'http://localhost:9009',
            redirectUrls: ['http://localhost:9009'],
            description:
                'Experiment to see if a web app can be configured for implicit and client credentials',
            logoUrl: 'http://localhost/icon.png',
        },
    };

    const snake = (str) =>
        str
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            .replace(/[^A-Za-z0-9]+/g, '.')
            .replace(/^-+|-+$/g, '')
            .toLowerCase();

    const template = appTypes.web;
    const body = { ...template, name: snake(name), title: name };

    return createApp(tenantId, body);
};

class AppsContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/apps/';
    title = 'Apps';

    getResource = (tenantId) =>
        this.props.splunkCloud.appreg
            .listApps()
            .then((apps) => apps.map((app) => ({ ...app, id: app.name })));

    createResource = (tenantId, resourceId, data) =>
        createAppFromTemplate(tenantId, data, (tenantId, body) => {
            return this.props.splunkCloud.appreg.createApp(body);
        });

    renderResource() {
        return (
            <Collection
                basePath={`/${this.props.tenantId}/apps/`}
                resourceType={this.props.resourceType}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }

    renderCreateDialog() {
        return (
            <CreateAppDialog
                open={this.state.createDialogOpen}
                title="Create App"
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={[]}
            />
        );
    }
}

export default AppsContainer;
