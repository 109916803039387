import React from 'react';

import ChevronLeft from '@splunk/react-icons/ChevronLeft';
import Button from '@splunk/react-ui/Button';

import UploadFile from '../components/UploadFile';
import ResourceContainerBase from './ResourceContainerBase';
import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import Content from '../components/Content';
import { AuthContext } from '../AuthContext';
import { isMediaTypeText, isMediaTypeImage } from '../mediaTypes';


const blobToDataURL = (data) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
            if (reader.error) {
                reject(reader.error);
            } else {
                resolve(reader.result);
            }
        }
    });
}

class FileContainer extends ResourceContainerBase {
    static contextType = AuthContext;
    static PATH = '/:tenantId/files/:resourceId/content';
    title = ' ';

    getResource = (tenantId, resourceId) => {
        return this.context.tokenSource.getAccessToken().then(accessToken => {
            const { tenantId, resourceId } = this.props;
            return fetch(`${this.context.app}/${tenantId}/file-drive/v0alpha0/files/${resourceId}/blob`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }).then(response => {
                const mediaType = response.headers.get('Content-Type');
                this.title = mediaType;
                if (isMediaTypeText(mediaType)) {                    
                    return response.text().then(content => {                    
                        return { mediaType, content };
                    });
                } else {
                    return response.blob().then(content => {

                        if (isMediaTypeImage(mediaType)) {
                            return blobToDataURL(content).then(dataURL => ({ mediaType, content: dataURL }));
                        }
                        return { mediaType, content };
                    });
                }
            });
        });
    };

    uploadFile = (file, data) => {
        const mediaType = file?.type || 'text/plain';
        return this.context.tokenSource.getAccessToken().then(accessToken => {
            const { tenantId, resourceId } = this.props;
            return fetch(`${this.context.app}/${tenantId}/file-drive/v0alpha0/files/${resourceId}/blob`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': mediaType
                },
                body: data || null
            }).then(() => {
                this.updateResource(tenantId, resourceId);
            });
        });
    };

    renderActions() {
        const { resourceId } = this.props;
        const actions = [];

        if (this.state.resource) {
            actions.push(
                <ConfirmationDialogActionButton
                    key="delete"
                    handleConfirmedAction={() => this.uploadFile()}
                    label="Clear"
                    message={`Are you sure you want to remove the content for "${resourceId}"?`}
                    onSuccess={() => undefined}
                    title={`Clear content for ${resourceId}`}
                />
            );
        }

        return actions;
    }

    renderResource() {
        const { tenantId, resourceId } = this.props;
        return (
            <div style={{ padding: '0 20px' }}>
                <div style={{ marginBottom: 20 }}>
                    <Button
                        size="small"
                        appearance="flat"
                        label="Metadata"
                        to={`/${tenantId}/files/${resourceId}`}
                        icon={<ChevronLeft screenReaderText="" hideDefaultTooltip size={1} />}
                    />
                </div>
                <UploadFile onUpload={this.uploadFile} />
                <Content mediaType={this.state.resource.mediaType} content={this.state.resource.content} />
            </div>
        );
    }
}

export default FileContainer;
