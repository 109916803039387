import React from 'react';

import { useParams } from 'react-router-dom';

import { TenantPicker } from '@splunk/tenant-picker';

import { TenantContext } from './TenantContext';

import NavContainer from './NavContainer';

import useTenants from './useTenants';

const TenantContainer = ({ appName, children }) => {
    const { tenantName } = useParams();
    const { tenants, error } = useTenants();

    if (error) {
        return <div>{error.message}</div>;
    }

    if (!tenants) {
        return null;
    }

    const found = tenants.indexOf(tenantName) >= 0;
    if (found) {
        return (
            <TenantContext.Provider value={tenantName}>
                <NavContainer appName={appName}>{children}</NavContainer>
            </TenantContext.Provider>
        );
    }

    const tenantsWithPaths = tenants.map((name) => ({ name }));
    return (
        <TenantPicker
            title="Welcome to the Cloud Admin Tool"
            selectedTenant={tenantName}
            tenants={tenantsWithPaths}
            onTenantSelected={(e, { tenant }) => {
                window.location = `/${tenant}`;
            }}
        />
    );
};

export default TenantContainer;
