import React from 'react';
import T from 'prop-types';

import ActionButton from '../DialogActionButton';
import UploadCSVDialog from './index';

const label = 'Upload CSV';

const UploadCSVDialogActionButton = ({ handleUploadCSVAction, onSuccess }) => (
    <ActionButton key="UploadCSVDialog" label={label} action={handleUploadCSVAction} onSuccess={onSuccess}>
        {({ handleAction, error, saving }) => (
            <UploadCSVDialog
                key="UploadCSVDialog"
                saving={saving}
                action={label}
                error={error}
                onCreate={handleAction}
                onCancel={handleAction}
            />
        )}
    </ActionButton>
);

UploadCSVDialogActionButton.propTypes = {
    handleUploadCSVAction: T.func.isRequired,
    onSuccess: T.func,
};

export default UploadCSVDialogActionButton;
