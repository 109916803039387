import React from 'react';
import { map, omit } from 'lodash';

import Button from '@splunk/react-ui/Button';
import Card from '@splunk/react-ui/Card';
import JSONTree from '@splunk/react-ui/JSONTree';
import P from '@splunk/react-ui/Paragraph';

import Cancel from '@splunk/react-icons/Cancel';

import Action from '../components/Action';
import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import DialogActionButton from '../components/DialogActionButton';
import TriggerDialog from '../components/TriggerDialog';
import ResourceContainerBase from './ResourceContainerBase';

const updateAction = (action, { name, value, values }) => {
    switch (name) {
        case 'webhookUrl':
        case 'message':
        case 'subject':
        case 'body':
            return { ...action, [name]: value };
        case 'addresses':
            return { ...action, [name]: value.split('\n') };
        default:
            // eslint-disable-next-line no-console
            console.log('update action ignored', name, value, values);
            return action;
    }
};

export const saveAction = (tenantId, actionName, body) =>
    this.props.splunkCloud.action.updateAction(actionName, body);

const parseMessageParams = (message) => {
    const r = /\{\{\s*\.([a-zA-Z_][a-zA-Z0-9_]*)\s*\}\}/g;

    const params = [];
    for (let match = r.exec(message); match !== null; match = r.exec(message)) {
        params.push(match[1]);
    }

    return params;
};

class ActionContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/actions/:resourceId/';
    getResource = () => this.props.splunkCloud.action.getAction(this.props.resourceId);

    saveResource = (tenantId, resourceId, action) => saveAction(tenantId, resourceId, action);
    handleDeleteAction = () => this.props.splunkCloud.action.deleteAction(this.props.resourceId);

    handleTriggerAction = ({ payload }) => {
        try {
            const json = JSON.parse(payload);
            const body = {
                kind: 'rawJSON',
                tenant: this.props.tenantId,
                payload: json,
            };
            return this.props.splunkCloud.action
                .triggerAction(this.props.resourceId, body)
                .then(({ statusId }) => {
                    this.setState({
                        triggers: {
                            ...this.state.triggers,
                            [statusId]: { state: 'UNKNOWN', payload: json, statusId },
                        },
                    });
                    this.watchTrigger(statusId);
                });
        } catch (e) {
            return Promise.reject(e);
        }
    };

    handleProperyChanged = (e, data) => {
        const resource = updateAction(this.state.resource, data);
        this.setState({ resource });
    };

    renderActions() {
        const { resourceId } = this.props;
        const actions = [
            <ConfirmationDialogActionButton
                key="delete"
                handleConfirmedAction={this.handleDeleteAction}
                label="Delete"
                message={`Are you sure you want to delete the "${resourceId}" collection?`}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}/`)
                }
                title={`Delete ${resourceId}`}
            />,
            <DialogActionButton key="trigger2" label="Trigger" action={this.handleTriggerAction}>
                {({ handleAction, error, saving }) => (
                    <TriggerDialog
                        key="ds"
                        error={error}
                        saving={saving}
                        name={resourceId}
                        params={parseMessageParams(this.state.resource.message || this.state.resource.body)}
                        onConfirm={handleAction}
                        onCancel={handleAction}
                    />
                )}
            </DialogActionButton>,
            <Button
                key="save"                
                onClick={this.handleSave}
                label="Save"
                appearance="primary"
            />,
        ];

        return actions;
    }

    watchTrigger = (triggerId) => {
        this.props.splunkCloud.action.getActionStatus(this.props.resourceId, triggerId).then((response) => {
            const trigger = this.state.triggers[triggerId];
            const poll =
                response.state === 'RUNNING'
                    ? setTimeout(() => {
                          this.watchTrigger(triggerId);
                      }, 1000)
                    : null;

            this.setState({
                triggers: {
                    ...this.state.triggers,
                    [triggerId]: { ...trigger, ...response, poll },
                },
            });
        });
    };

    componentWillUnmount() {
        if (this.state.triggers) {
            Object.values(this.state.triggers).forEach((trigger) => {
                if (trigger.poll) {
                    clearTimeout(trigger.poll);
                }
            });
        }
    }

    renderTrigger = (trigger) => {
        const onClick = () => this.setState({ triggers: omit(this.state.triggers, [trigger.statusId]) });

        return (
            <Card key={trigger.statusId} style={{ maxWidth: 400 }}>
                <Card.Header title={trigger.statusId} subtitle={trigger.state}>
                    <div style={{ textAlign: 'right', color: '#f1b10e' }}>
                        <Button icon={<Cancel />} appearance="pill" inline onClick={onClick} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <P>{trigger.message}</P>
                    <JSONTree json={trigger.payload} expandChildren />
                </Card.Body>
            </Card>
        );
    };

    renderTriggers(triggers) {
        if (!triggers) {
            return null;
        }

        return <div style={{ padding: 8 }}>{map(triggers, this.renderTrigger)}</div>;
    }
    // eslint-disable-next-line class-methods-use-this
    renderResource(resource) {
        return (
            <div>
                <Action action={resource} onPropertyChanged={this.handleProperyChanged} />
                {this.renderTriggers(this.state.triggers)}
            </div>
        );
    }
}

export default ActionContainer;
