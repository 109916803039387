import React from 'react';
import PropTypes from 'prop-types';

import ControlGroup from '@splunk/react-ui/ControlGroup';
import RadioBar from '@splunk/react-ui/RadioBar';

const help = {
    web:
        'Traditional web apps that have a server-side component acting on behalf of a user (auth code). Standalone services acting on their own behalf via the app principal (client credentials).',
    native: 'Native apps on Android, iOS, Mac OSX, Windows, Linux etc...',
    service: 'Standalone services acting on their own behalf via the app principal (client credentials).',
};

const AppKind = ({ disabled, onChange, value }) => (
    <ControlGroup label="Type" help={help[value]}>
        <RadioBar value={value} name="kind" onChange={onChange}>
            <RadioBar.Option label="Web" value="web" disabled={disabled} />
            <RadioBar.Option label="Native" value="native" disabled={disabled} />
            <RadioBar.Option label="Service" value="service" disabled={disabled} />
        </RadioBar>
    </ControlGroup>
);

AppKind.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOf(Object.keys(help)).isRequired,
};

export default AppKind;
