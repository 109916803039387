import React, { Component } from 'react';
import T from 'prop-types';

import Table from '@splunk/react-ui/Table';
import Button from '@splunk/react-ui/Button';
import P from '@splunk/react-ui/Paragraph';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const columns = [
    { sortKey: 'appName', label: 'Name' },
    { sortKey: 'title', label: 'Title' },
    { sortKey: 'createdBy', label: 'Created By' },
    { sortKey: 'createdAt', label: 'Created At' },
];

const Subscription = {
    appName: T.string,
    title: T.string,
    description: T.string, // eslint-disable-line
    logoURL: T.string, // eslint-disable-line
    loginURL: T.string, // eslint-disable-line
    createdBy: T.string,
    createdAt: T.string,
};

const dateTime = (iso) => {
    const d = new Date(iso);
    return `${d.toDateString()} ${d.toLocaleTimeString()}`;
};

function getExpansionRow(row, onUnsubscribe) {
    return (
        <Table.Row key={`${row.appName}-expansion`}>
            <Table.Cell style={{ borderTop: 'none' }} colSpan={4}>
                <P>{row.description}</P>
                <Button appearance="primary" to={row.loginURL} openInNewContext>
                    Launch
                </Button>
                {onUnsubscribe ? (
                    <Button onClick={() => onUnsubscribe(row.appName)}>Unsubscribe</Button>
                ) : null}
            </Table.Cell>
        </Table.Row>
    );
}

class Subscriptions extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            sortKey: 'appName',
            sortDir: 'asc',
        };
    }

    handleSort = (e, { sortKey }) => {
        const prevSortKey = this.state.sortKey;
        const prevSortDir = prevSortKey === sortKey ? this.state.sortDir : 'none';
        const nextSortDir = prevSortDir === 'asc' ? 'desc' : 'asc';
        this.setState({
            sortKey,
            sortDir: nextSortDir,
        });
    };

    render() {
        const { sortKey, sortDir } = this.state;
        if (!this.props.subscriptions) {
            return <WaitSpinner size="medium" />;
        }
        return (
            <Table key="subscriptions" rowExpansion="single">
                <Table.Head>
                    {columns.map((headData) => (
                        <Table.HeadCell
                            key={headData.sortKey}
                            onSort={this.handleSort}
                            sortKey={headData.sortKey}
                            sortDir={headData.sortKey === sortKey ? sortDir : 'none'}
                        >
                            {headData.label}
                        </Table.HeadCell>
                    ))}
                </Table.Head>
                <Table.Body>
                    {this.props.subscriptions
                        .sort((rowA, rowB) => {
                            if (sortDir === 'asc') {
                                return rowA[sortKey] > rowB[sortKey] ? 1 : -1;
                            } else if (sortDir === 'desc') {
                                return rowB[sortKey] > rowA[sortKey] ? 1 : -1;
                            }
                            return 0;
                        })
                        .map((row) => (
                            <Table.Row
                                key={row.appName}
                                expansionRow={getExpansionRow(row, this.props.onUnsubscribe)}
                            >
                                <Table.Cell>{row.appName}</Table.Cell>
                                <Table.Cell>{row.title}</Table.Cell>
                                <Table.Cell>{row.createdBy}</Table.Cell>
                                <Table.Cell>{dateTime(row.createdAt)}</Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        );
    }
}

Subscriptions.propTypes = {
    subscriptions: T.arrayOf(T.shape(Subscription)),
    onUnsubscribe: T.func,
};

export default Subscriptions;
