import React from 'react';

import Datasets from '../components/Datasets';
import ResourceContainerBase from './ResourceContainerBase';

class DatasetsBase extends ResourceContainerBase {
    subTabs() {
        return [
            { label: 'Indexes', resourceType: 'indexes' },
            { label: 'Views', resourceType: 'views' },
            { label: 'Collections', resourceType: 'collections' },
            { label: 'Lookups', resourceType: 'lookups' },
            { label: 'Searches', resourceType: 'searches' },
            { label: 'Modules', resourceType: 'modules' },
            { label: 'Datasets', resourceType: 'metadata' },
            { label: 'Other', resourceType: 'datasets' },
        ];
    }

    renderDatasetsList(showKindColumn) {
        return (
            <Datasets
                basePath={`/${this.props.tenantId}/datasets/`}
                datasets={this.state.resource}
                history={this.props.history}
                showKindColumn={showKindColumn}
            />
        );
    }
}

export default DatasetsBase;
