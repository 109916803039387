import React from 'react';

import Collection from '../components/Collection';

import ResourceContainerBase from './ResourceContainerBase';

class RulesContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/rules/';
    title = 'Rules';

    getResource = (tenantId) => this.props.splunkCloud.catalog.listRules();

    createResource = (tenantId, resourceId, data) =>
        this.props.splunkCloud.identity.createRole({ name: data.name, permissions: [] });

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/rules/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default RulesContainer;
