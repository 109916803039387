import React from 'react';
import PropTypes from 'prop-types';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Text from '@splunk/react-ui/Text';

import AppKind from '../AppKind';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    kind: PropTypes.string.isRequired,
    name: PropTypes.string,
};

function CollectionForm({ kind, name, onChange }) {
    return [
        <AppKind value={kind} onChange={onChange} />,
        <ControlGroup key="name" label="Name">
            <Text name="name" value={name} placeholder="Name of the App" onChange={onChange} />
        </ControlGroup>,
    ];
}

CollectionForm.propTypes = propTypes;
export default CollectionForm;
