import React from 'react';
import styled from 'styled-components';
import Heading from '@splunk/react-ui/Heading';
import P from '@splunk/react-ui/Paragraph';
import Link from '@splunk/react-ui/Link';

import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import CreateRecordDialogActionButton from '../components/CreateRecordDialog/ActionButton';
import UploadCSVDialogActionButton from '../components/UploadCSVDialog/ActionButton';
import Metadata from '../components/Metadata';

import DatasetsBase from './DatasetsBase';
import JSONTree from '@splunk/react-ui/JSONTree';

const Links = styled.ul`
    list-style-type: none;

    & > li {
        display: inline;
        margin-right: 10px;
    }
`;

class CollectionContainer extends DatasetsBase {
    static PATH = '/:tenantId/collections/:resourceId/';
    getResource = () => {
        const collectionPromise = this.props.splunkCloud.catalog.getDataset(this.props.resourceId);
        const lookupsPromise = this.props.splunkCloud.catalog.listDatasets({
            filter: `kind="lookup" AND externalName="${this.props.resourceId}"`,
        });
        const recordsPromise = this.props.splunkCloud.kvstore.listRecords(this.props.resourceId, {
            count: 20,
            offset: 0,
        });

        return Promise.all([collectionPromise, lookupsPromise, recordsPromise]).then(
            ([collection, lookups, records]) => {
                return { ...collection, lookups, records };
            }
        );
    };

    handleDeleteCollection = () => this.props.splunkCloud.catalog.deleteDataset(this.props.resourceId);

    handleCreateRecordAction = ({ record }) =>
        this.props.splunkCloud.kvstore.insertRecord(this.props.resourceId, record);

    handleUploadCSVAction = ({ content }) => {
        const [header, ...rows] = content.trim().split('\n');
        const fields = header.split(',');

        const records = rows.map((row) => {
            const values = row.split(',');
            const record = {};
            fields.forEach((field, index) => {
                record[field] = values[index];
            });

            return record;
        });

        return this.props.splunkCloud.kvstore.insertRecords(this.props.resourceId, records);
    };

    renderResource() {
        return (
            <>
                <Metadata data={this.state.resource} />
                <Heading level={3}>Lookups</Heading>
                {this.state.resource.lookups.length === 0 && (
                    <P>There are no lookups defined for this collection.</P>
                )}
                <Links>
                    {this.state.resource.lookups.map((lookup) => (
                        <li key={lookup.name}>
                            <Link to={`/${this.props.tenantId}/datasets/${lookup.name}`}>{lookup.name}</Link>
                        </li>
                    ))}
                </Links>
                <Heading level={3}>Records</Heading>
                <JSONTree json={this.state.resource.records} expandChildren />
            </>
        );
    }

    renderActions() {
        const { resourceId } = this.props;
        return [
            <ConfirmationDialogActionButton
                key="delete"
                handleConfirmedAction={this.handleDeleteCollection}
                label="Delete"
                message={`Are you sure you want to delete the "${resourceId}" collection?`}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}/`)
                }
                title={`Delete ${resourceId}`}
            />,
            <UploadCSVDialogActionButton
                key="upload"
                resourceType="record"
                handleUploadCSVAction={this.handleUploadCSVAction}
                onSuccess={() => this.updateResource(this.props.tenantId, this.state.resource.name)}
            />,
            <CreateRecordDialogActionButton
                key="create"
                resourceType="record"
                handleCreateRecordAction={this.handleCreateRecordAction}
                onSuccess={() => this.updateResource(this.props.tenantId, this.state.resource.name)}
            />,
        ];
    }
}

export default CollectionContainer;
