import React from 'react';

import CreateDatasetDialog from '../components/CreateDatasetDialog';

import DatasetsBase from './DatasetsBase';

class DatasetsContainer extends DatasetsBase {
    static PATH = '/:tenantId/datasets/';
    title = 'Other Datasets';

    getResource = (tenantId) =>
        this.props.splunkCloud.catalog.listDatasets({
            filter:
                'kind!="job" AND kind!="index" AND kind!="metric" AND kind!="kvcollection" AND kind!="lookup" AND kind!="view"',
        });

    createResource = (tenantId, resourceId, data) => this.props.splunkCloud.catalog.createDataset(data);

    renderActions() {
        return [];
    }

    renderResource() {
        return (
            <div>
                {this.renderDatasetsList(true)}
                {this.state.createDialogOpen ? (
                    <CreateDatasetDialog
                        open={this.state.createDialogOpen}
                        resourceType="App"
                        error={this.state.dialogError}
                        onCreate={this.handleCreate}
                        onCancel={this.handleCreate}
                        saving={this.state.saving}
                        modules={this.state.resource.map((dataset) => dataset.module)}
                    />
                ) : null}
            </div>
        );
    }

    renderCreateDialog() {
        return (
            <CreateDatasetDialog
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={this.state.resource.map((dataset) => dataset.module)}
            />
        );
    }
}

export default DatasetsContainer;
