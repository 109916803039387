import React from 'react';

import Collection from '../components/Collection';

import ResourceContainerBase from './ResourceContainerBase';

const s2o = ({ name }) => ({ id: name });

class TenantsContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/tenants/';
    title = 'Tenants';

    getResource = () =>
        this.props.splunkCloud.provisioner.listTenants().then((response) => response.map(s2o));

    createResource = (tenantId, resourceId, data) =>
        this.props.splunkCloud.identity.createTenant({ name: data.name });

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/tenants/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default TenantsContainer;
