import { SplunkCloud } from '@splunkdev/cloud-sdk';

const { useState, useEffect } = require('react');
const { useAuth } = require('./AuthContext');

const useTenants = () => {
    const [state, setState] = useState({});
    const { tokenSource, api, app, user, error } = useAuth();

    useEffect(() => {
        if (!error) {
            const splunkCloud = new SplunkCloud({
                urls: { api, app },
                tokenSource,
                defaultTenant: 'system',
            });

            splunkCloud.identity.getPrincipal(user).then(
                ({ tenants }) => setState({ tenants: tenants || [] }),
                (error) => setState({ error })
            );
        }
    }, [tokenSource, user, api, app, error]);

    return state;
};

export default useTenants;
