import React, { Component } from 'react';
import File from '@splunk/react-ui/File';

class UploadFile extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.fileReader = new FileReader();
    }

    handleAddFiles = files => {
        if (files.length > 0) {
            const file = files[0];
            if (this.fileReader.readyState === 1) {
                this.fileReader.abort();
            }

            this.fileReader.onload = () => {
                this.setState({ filename: file.name });
                if (this.props.onUpload) {
                    this.props.onUpload(file, this.fileReader.result);
                }
            };

            this.fileReader.readAsArrayBuffer(file);
        }
    };

    handleRemoveFile = () => {
        if (this.fileReader.readyState === 1) {
            this.fileReader.abort();
        }

        this.setState({ filename: undefined });
    };

    render() {
        return (
            <div style={{ maxWidth: 300 }}>
                <File onRequestAdd={this.handleAddFiles} onRequestRemove={this.handleRemoveFile}>
                    {this.state.filename && <File.Item name={this.state.filename} />}
                </File>
            </div>
        );
    }
}

export default UploadFile;
