import React from 'react';
import { omit } from 'lodash';
import Button from '@splunk/react-ui/Button';

import { App } from '../components/App';
import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';

import ResourceContainerBase from './ResourceContainerBase';

const updateApp = (app, { name, value, values }) => {
    switch (name) {
        case 'title':
        case 'kind':
        case 'description':
        case 'loginUrl':
        case 'applicationType':
        case 'logoUrl':
        case 'setupUrl':
        case 'webhookUrl':
            return { ...app, [name]: value };

        case 'appPrincipalPermissions':
        case 'userPermissionsFilter':
        case 'redirectUrls':
            return { ...app, [name]: value.split('\n') };

        default:
            // eslint-disable-next-line no-console
            console.log('update app ignored', name, value, values);
            return app;
    }
};

const showSecret = (app) => {
    // eslint-disable-next-line no-alert
    alert(app.clientSecret);
};

class AppContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/apps/:resourceId/';
    getResource = (tenantId, appId) => {
        const app$ = this.props.splunkCloud.appreg.getApp(appId);
        const tenants$ = this.props.splunkCloud.appreg.listAppSubscriptions(appId);
        return Promise.all([app$, tenants$]).then(([app, tenants]) => ({
            ...app,
            tenants: tenants.map(({ tenant }) => tenant),
        }));
    };

    deleteResource = () => {
        return this.props.splunkCloud.appreg.deleteApp(this.props.resourceId);
    };

    saveResource = (tenantId, appId, app) => {
        const body = omit(app, ['name', 'createdBy', 'clientId', 'createdAt', 'kind', 'tenants']);
        return this.props.splunkCloud.appreg.updateApp(app.name, body);
    };

    handleRotateSecret = () => {
        return this.props.splunkCloud.appreg.rotateSecret(this.props.resourceId);
    };

    handleGetSecret = () => {
        return this.props.splunkCloud.appreg.getApp(this.props.resourceId).then((app) => {
            return this.saveResource(this.props.tenantId, this.props.resourceId, app);
        });
    };

    handleProperyChanged = (e, data) => {
        const resource = updateApp(this.state.resource, data);
        this.setState({ resource });
    };

    renderActions() {
        return [
            <ConfirmationDialogActionButton
                key="rotate-secret"
                label="Rotate Secret"
                title="Rotate Secret"
                message={`Rotate the secret for "${this.props.resourceId}"?`}
                handleConfirmedAction={this.handleRotateSecret}
                onSuccess={showSecret}
            />,
            <Button
                key="get-secret"                
                onClick={() => {
                    this.handleGetSecret().then(showSecret);
                }}
                label="Show Secret"
                appearance="primary"
            />,
            <ConfirmationDialogActionButton
                key="delete"
                label="Delete"
                title={`Delete ${this.props.resourceId}`}
                message={`Delete the "${this.props.resourceId}" app?`}
                handleConfirmedAction={this.deleteResource}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}`)
                }
            />,
            <Button
                key="save"
                onClick={this.handleSave}
                label="Save"
                appearance="primary"
            />,
        ];
    }

    // eslint-disable-next-line class-methods-use-this
    renderResource(resource) {
        return <App app={resource} onPropertyChanged={this.handleProperyChanged} />;
    }
}

export default AppContainer;
