import ResourceContainerBase from './ResourceContainerBase';

class PermissionsBase extends ResourceContainerBase {
    subTabs() {
        return [
            { label: 'Users', resourceType: 'users' },
            { label: 'Groups', resourceType: 'groups' },
            { label: 'Roles', resourceType: 'roles' },
        ];
    }
}

export default PermissionsBase;
