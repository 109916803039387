import React, { useState } from 'react';
import T from 'prop-types';
import styled from 'styled-components';

import Button from '@splunk/react-ui/Button';
import Text from '@splunk/react-ui/Text';

const StyledEditSearch = styled.div`
    display: flex;
    gap: 10px;
    align-items: start;
    padding: 20px;
    

    label {
        font-weight: bold;
        flex: 0 0 auto;
    }
    label:after { 
        content: ":"; 
        padding-left: 2px;
    }

    textarea {
        flex: 1 1 auto;
        min-width: 0;
        font-family: monospace;
    }

    button {
        flex: 0 0 auto;
    }
`;

export const EditSearch = ({title, search, onUpdateSearch}) => {
    const [spl, setSPL] = useState(search);

    const handleChangeSearch = (e, data) => {
        setSPL(data.value);
    };
    const handleUpdateSearch = () => {
        onUpdateSearch(spl);
    };

    return (
        <StyledEditSearch>
            {title && <label for="view-spl">{title}</label>}
            <Text inputId={title ? "view-spl": undefined} value={spl} onChange={handleChangeSearch} rowsMax={8}  multiline />
            <Button inline appearance="flat" onClick={handleUpdateSearch} disabled={!spl || spl === search} >Update</Button>
        </StyledEditSearch>
    );  
}

EditSearch.propTypes = {
    search: T.string.isRequired,
    onUpdateSearch: T.func.isRequired,
};


export default EditSearch