import React from 'react';

import Group from '../components/Group';

import PermissionsBase from './PermissionsBase';

class GroupContainer extends PermissionsBase {
    static PATH = '/:tenantId/groups/:resourceId/';
    getResource = (tenantId, groupId) => {
        const client = this.props.splunkCloud;
        return Promise.all([
            client.identity.getGroup(groupId),
            client.identity.listGroupRoles(groupId),
            client.identity.listGroupMembers(groupId),
            client.identity.listRoles(),
            client.identity.listMembers(),
        ]).then(([group, roles, members, allRoles, allUsers]) => ({
            ...group,
            roles,
            members,
            allRoles,
            allUsers,
        }));
    };

    deleteResource = (tenantId, groupId) => this.props.splunkCloud.identity.deleteGroup(groupId);

    handleAddRoles = (roles) => {
        const groupName = this.props.resourceId;
        roles.forEach((roleId) =>
            this.props.splunkCloud.identity.addGroupRole(groupName, { name: roleId }).catch(this.handleError)
        );

        this.setState({
            resource: {
                ...this.state.resource,
                roles: [...this.state.resource.roles, ...roles],
            },
        });
    };

    handleRemoveRoles = (roles) => {
        const groupName = this.props.resourceId;
        roles.forEach((roleId) =>
            this.props.splunkCloud.identity.removeGroupRole(groupName, roleId).catch(this.handleError)
        );
        const rolesSet = new Set(roles);
        this.setState({
            resource: {
                ...this.state.resource,
                roles: this.state.resource.roles.filter((role) => !rolesSet.has(role)),
            },
        });
    };

    handleAddMembers = (users) => {
        const groupName = this.props.resourceId;
        users.forEach((userId) =>
            this.props.splunkCloud.identity
                .addGroupMember(groupName, { name: userId })
                .catch(this.handleError)
        );
        this.setState({
            resource: {
                ...this.state.resource,
                members: [...this.state.resource.members, ...users],
            },
        });
    };

    handleRemoveMembers = (users) => {
        const groupName = this.props.resourceId;
        users.forEach((userId) =>
            this.props.splunkCloud.identity.removeGroupMember(groupName, userId).catch(this.handleError)
        );
        const usersSet = new Set(users);
        this.setState({
            resource: {
                ...this.state.resource,
                members: this.state.resource.members.filter((user) => !usersSet.has(user)),
            },
        });
    };

    renderResource = (group) => (
        <Group
            group={group.name}
            roles={group.roles}
            allRoles={group.allRoles}
            members={group.members}
            allUsers={group.allUsers}
            onAddRoles={this.handleAddRoles}
            onRemoveRoles={this.handleRemoveRoles}
            onAddMembers={this.handleAddMembers}
            onRemoveMembers={this.handleRemoveMembers}
        />
    );
}

export default GroupContainer;
