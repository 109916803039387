import React from 'react';

import Message from '@splunk/react-ui/Message';
import P from '@splunk/react-ui/Paragraph';
import Link from '@splunk/react-ui/Link';

const Error = ({ error, large }) => {
    if (!error) {
        return null;
    }
    const size = large ? 600 : 400;
    const imgStyle = {
        width: '100%',
        maxWidth: size,
        maxHeight: size,
        objectFit: 'contain',
        margin: '8px auto',
        display: 'block',
    };

    const message = typeof error === 'string' ? error : error.message;

    const forbidden =
        error.httpStatusCode === 403 ? (
            <P>
                403 errors can occur when your tenant hasn&apos;t been subscribed to the Cloud Admin Tool, the
                home page after <Link to="/">picking a tenant</Link> has instructions on how to{' '}
                <b>subscribe</b>.
            </P>
        ) : null;

    const content = [
        <Message key="message" fill type="error">
            {message}
            {forbidden}
        </Message>,
    ];

    if (error.httpStatusCode) {
        content.push(
            <img
                key="cat"
                style={imgStyle}
                src={`https://http.cat/${error.httpStatusCode}`}
                alt={error.httpStatusCode}
            />
        );
    }
    return content;
};

export default Error;
