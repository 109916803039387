import React from 'react';

import Collection from '../components/Collection';
import CreateActionDialog from '../components/CreateActionDialog';

import ResourceContainerBase from './ResourceContainerBase';

class ActionsContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/actions/';
    title = 'Actions';

    getResource = (tenantId) =>
        this.props.splunkCloud.action
            .listActions()
            .then((actions) => actions.map((action) => ({ id: action.name })));

    createResource = (tenantId, resourceId, data) => {
        const action = {
            name: data.name,
            kind: data.kind,
        };

        if (data.kind === 'email') {
            action.addresses = [];
            action.body = '{"count": "{{ .total_users }}"}';
            action.subject = 'Email Action';
        } else {
            action.message = '{"count": "{{ .total_users }}"}';
            action.webhookUrl = 'https://webhook.site/e3f5d21f-8933-4dc8-a3b7-4664b7a8cbae';
        }

        return this.props.splunkCloud.action.createAction(action);
    };

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/actions/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }

    renderCreateDialog() {
        return (
            <CreateActionDialog
                open={this.state.createDialogOpen}
                title="Create Action"
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={[]}
            />
        );
    }
}

export default ActionsContainer;
