import React from 'react';
import PropTypes from 'prop-types';

import ControlGroup from '@splunk/react-ui/ControlGroup';
import RadioBar from '@splunk/react-ui/RadioBar';

const help = {
    email: 'An action that sends an email',
    webhook: 'An action that posts to a web hook',
};

const ActionKind = ({ disabled, onChange, value }) => (
    <ControlGroup label="Type" help={help[value]}>
        <RadioBar value={value} name="kind" onChange={onChange}>
            <RadioBar.Option key="email" label="Email" value="email" disabled={disabled} />
            <RadioBar.Option key="webhook" label="Web Hook" value="webhook" disabled={disabled} />
        </RadioBar>
    </ControlGroup>
);

ActionKind.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOf(Object.keys(help)).isRequired,
};

export default ActionKind;
