import React, { Component } from 'react';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';

import Text from '@splunk/react-ui/Text';

import Clear from '@splunk/react-icons/Clear';

import Error from './Error';

const containerStyle = { maxWidth: 600, margin: '40px auto' };
const addStyle = { display: 'flex' };
const permissionRowStyle = {
    display: 'flex',
    backgroundColor: '#026440',
    marginTop: 8,
    borderRadius: 4,
};
const permissionStyle = { flexGrow: 1, margin: 6 };
const buttonStyle = { flexGrow: 0 };

class Role extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            permissionValue: '',
        };
    }

    handlePermissionChange = (e, { value }) => {
        this.setState({ permissionValue: value });
    };

    handleAddPermission = () => {
        this.props.onAddPermission(this.state.permissionValue);
    };

    handleRemovePermission = (e, { value }) => {
        this.props.onRemovePermission(value);
    };

    render() {
        const { permissions, error } = this.props;
        return (
            <div>
                <div style={containerStyle}>
                    <Error error={error} />
                    <div key="add" style={addStyle}>
                        <Text
                            autoFocus
                            onChange={this.handlePermissionChange}
                            value={this.state.permissionValue}
                            append
                            placeholder="{ tenant } : { resource } : { scope }"
                            canClear
                        />
                        <div style={buttonStyle}>
                            <Button
                                disabled={!this.state.permissionValue}
                                inline
                                appearance="primary"
                                label="Add"
                                prepend
                                onClick={this.handleAddPermission}
                            />
                        </div>
                    </div>
                    {permissions.map((permission) => (
                        <div key={permission} style={permissionRowStyle}>
                            <pre style={permissionStyle}>{permission}</pre>
                            <div style={buttonStyle}>
                                <Button
                                    inline
                                    appearance="primary"
                                    icon={<Clear />}
                                    value={permission}
                                    prepend
                                    onClick={this.handleRemovePermission}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

Role.propTypes = {
    role: T.string.isRequired,
    permissions: T.arrayOf(T.string).isRequired,
    onAddPermission: T.func.isRequired,
    onRemovePermission: T.func.isRequired,
    error: T.object,
};

export default Role;
