import React from 'react';
import T from 'prop-types';
import Button from '@splunk/react-ui/Button';
import Modal from '@splunk/react-ui/Modal';

import P from '@splunk/react-ui/Paragraph';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from './Error';

const ConfirmationDialog = ({ action, error, message, onCancel, onConfirm, saving, title }) => (
    <Modal onRequestClose={onCancel} open style={{ width: '600px' }}>
        <Modal.Header title={title} />
        <Modal.Body>
            <Error error={error} />
            <P>{message}</P>
        </Modal.Body>
        <Modal.Footer>
            {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
            <Button disabled={saving} appearance="secondary" onClick={(e) => onCancel(e)} label="Cancel" />
            <Button
                disabled={saving}
                appearance="secondary"
                onClick={(e) => onConfirm(e, { confirmed: true })}
                label={action}
            />
        </Modal.Footer>
    </Modal>
);

ConfirmationDialog.propTypes = {
    saving: T.bool.isRequired,

    title: T.string.isRequired,
    message: T.string.isRequired,
    action: T.string.isRequired,

    error: T.object,
    onConfirm: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default ConfirmationDialog;
