const SCS_ENVIRONMENT_KEY = 'scs-environment';

export const COMPLIANT_PLAYGROUND = 'gplay';
export const COMPLIANT_STAGING = 'gstage';
export const PLAYGROUND = 'playground';
export const STAGING = 'staging';

export const changeCurrentEnvironment = (newEnviroment) => {
    switch (newEnviroment) {
        case COMPLIANT_PLAYGROUND:
        case COMPLIANT_STAGING:
        case PLAYGROUND:
        case STAGING:
            window.localStorage.setItem(SCS_ENVIRONMENT_KEY, newEnviroment);
            window.sessionStorage.removeItem('splunk-token-storage');
            window.location = '/';
            break;
        default:
            alert(`Unknown environment: ${newEnviroment}`);
    }
};

export const getCurrentEnvironment = () => {
    const currentEnviroment = window.localStorage.getItem(SCS_ENVIRONMENT_KEY);
    switch (currentEnviroment) {
        case COMPLIANT_PLAYGROUND:
        case COMPLIANT_STAGING:
        case PLAYGROUND:
        case STAGING:
            return currentEnviroment;
        default:
            return COMPLIANT_PLAYGROUND;
    }
};
