import React from 'react';
import T from 'prop-types';

import ActionButton from '../DialogActionButton';
import CreateFieldDialog from './index';

const label = 'Create Field';

const CreateRecordDialogActionButton = ({ handleCreateFieldAction, onSuccess }) => (
    <ActionButton
        key="CreateFieldButton"
        primary
        label={label}
        action={handleCreateFieldAction}
        onSuccess={onSuccess}
    >
        {({ handleAction, error, saving }) => (
            <CreateFieldDialog
                key="CreateFieldButton"
                saving={saving}
                action={label}
                error={error}
                onCreate={handleAction}
                onCancel={handleAction}
            />
        )}
    </ActionButton>
);

CreateRecordDialogActionButton.propTypes = {
    handleCreateFieldAction: T.func.isRequired,
    onSuccess: T.func,
};

export default CreateRecordDialogActionButton;
