import React from 'react';
import DatasetsBase from './DatasetsBase';



import Datasets from '../components/Datasets';

class MetadataContainer extends DatasetsBase {
    static PATH = '/:tenantId/metadata/';
    title = 'Datasets';

    getResource = (tenantId) =>
        {
            return this.props.splunkCloud.client
                .get('api', `/${tenantId}/metadatacatalog/v3alpha2/datasets`).then((response) => {
                    console.log(response);
                    const results = response?.body || [];
                    return results.map(({name, owner, namespace, kind}) => ({name, module: namespace, kind, owner, resourcename: namespace ? `${namespace}.${name}`: name}));
                });
        };
    renderResource() {
        return (<Datasets
                basePath={`/${this.props.tenantId}/datasets/`}
                datasets={this.state.resource}
                history={this.props.history}
                showKindColumn={true}
        />);
    }
}

export default MetadataContainer;
