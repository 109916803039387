import React from 'react';

import Button from '@splunk/react-ui/Button';
import H from '@splunk/react-ui/Heading';

import ResourceContainerBase from './ResourceContainerBase';

import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import Subscriptions from '../components/Subscriptions';
import Tenant from '../components/Tenant';
import SubscriptionDialog from '../components/SubscriptionDialog';
import Error from '../components/Error';

const apiVersion = 'v2beta1';

class TenantContainer extends ResourceContainerBase {
    static PATH = '/:tenantId/tenants/:resourceId/';
    getResource = (ignored, tenantId) => {
        this.props.splunkCloud.appreg.listSubscriptions().then(
            (subscriptions) => {
                this.setState({ subscriptions, subscriptionsError: null });
            },
            (error) => this.setState({ subscriptionsError: error })
        );

        this.props.splunkCloud.appreg.listApps().then((apps) => {
            this.setState({ apps });
        }, console.error); // eslint-disable-line no-console

        return this.props.splunkCloud.provisioner.getTenant(tenantId);
    };

    resumeTenant = () =>
        this.props.splunkCloud.client.patch(
            'api',
            `/system/identity/${apiVersion}/tenants/${this.props.resourceId}`,
            {
                state: 'resume',
            }
        );

    suspendTenant = () =>
        this.props.splunkCloud.client.patch(
            'api',
            `/system/identity/${apiVersion}/tenants/${this.props.resourceId}`,
            {
                state: 'suspend',
            }
        );

    handleSubscriptionCancel = () => {
        this.setState({ subscriptionOpen: false });
    };

    handleSubscribe = (e, { name }) => {
        this.setState({ savingSubscription: true });

        this.props.splunkCloud.appreg.createSubscription(name).then(
            () => {
                this.setState({
                    savingSubscription: false,
                    subscriptionOpen: false,
                    subscriptionError: null,
                });
                this.updateResource(this.props.tenantId, this.props.resourceId);
            },
            (subscriptionError) => {
                this.setState({
                    savingSubscription: false,
                    subscriptionError,
                });
            }
        );
    };

    handleUnsubscribe = (appName) => {
        this.props.splunkCloud.appreg.deleteSubscription(appName).then(
            () => {
                this.updateResource(this.props.tenantId, this.props.resourceId);
            },
            (error) => {
                this.setState({ error });
            }
        );
    };

    renderResource() {
        return (
            <div style={{ margin: 8 }}>
                <Tenant tenant={this.state.resource} />
                <H level={3}>Subscriptions</H>
                {this.state.subscriptionsError ? (
                    <Error error={this.state.subscriptionsError} />
                ) : (
                    <Subscriptions
                        subscriptions={this.state.subscriptions}
                        onUnsubscribe={this.handleUnsubscribe}
                    />
                )}
            </div>
        );
    }

    renderSuspendResume = () => {
        if (this.state.resource && this.state.resource.status === 'suspended') {
            return (
                <ConfirmationDialogActionButton
                    key="resume"
                    label="Resume"
                    title={`Resume ${this.props.resourceId}`}
                    message={`Resume the "${this.props.resourceId}" tenant?`}
                    handleConfirmedAction={this.resumeTenant}
                    onSuccess={() => this.updateResource(this.props.tenantId, this.props.resourceId)}
                />
            );
        }

        return (
            <ConfirmationDialogActionButton
                key="suspend"
                label="Suspend"
                title={`Suspend ${this.props.resourceId}`}
                message={`Suspend the "${this.props.resourceId}" tenant?`}
                handleConfirmedAction={this.suspendTenant}
                onSuccess={() => this.updateResource(this.props.tenantId, this.props.resourceId)}
            />
        );
    };

    renderActions() {
        return [
            <Button
                key="subscribe"
                onClick={() => {
                    this.setState({ subscriptionOpen: true, savingSubscription: false });
                }}
                label="Subscribe"
                appearance="primary"
            />,
            this.state.subscriptionOpen ? (
                <SubscriptionDialog
                    key="subscription-dialog"
                    onCancel={this.handleSubscriptionCancel}
                    onSubscribe={this.handleSubscribe}
                    saving={this.state.savingSubscription}
                    error={this.state.subscriptionError}
                />
            ) : null,
            this.renderSuspendResume(),
        ];
    }
}

export default TenantContainer;
