import React from 'react';

import Collection from '../components/Collection';

import IngestBase from './IngestBase';

class TokensContainer extends IngestBase {
    static PATH = '/:tenantId/tokens/';
    title = 'Tokens';

    getResource = (tenantId) => {
        return this.props.splunkCloud.ingest.listCollectorTokens().then((tokens) => {
            return tokens.map((token) => ({ id: token.name }));
        });
    };

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/tokens/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }
}

export default TokensContainer;
