import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';

import Button from '@splunk/react-ui/Button';
import Heading from '@splunk/react-ui/Heading';

const Container = styled.div`
    display: flex;

    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
        padding: 0;
        margin: 0;
    }
`;

const Buttons = styled.div`
    text-align: right;
`;

const Header = ({ children, title, singular, onCreate, onSave, onDelete }) => (
    <Container>
        <Heading level={2}>{title}</Heading>
        <Buttons>
        
            {onCreate ? (
                <Button
                    key="Create"
                    
                    onClick={onCreate}
                    inline
                    label={`Create ${singular}`}
                    appearance="primary"
                />
            ) : null}
            {onDelete ? (
                <Button
                    key="Delete"
                    
                    onClick={onDelete}
                    label="Delete"
                    appearance="secondary"
                />
            ) : null}
            {onSave ? (
                <Button
                    key="Save"
                    
                    onClick={onSave}
                    label="Save"
                    appearance="primary"
                />
            ) : null}
            {children}
        
        </Buttons>
    </Container>
);

Header.propTypes = {
    children: T.node,
    title: T.string.isRequired,
    onCreate: T.func,
    onSave: T.func,
    onDelete: T.func,
};

export default Header;
