import React from 'react';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';

import Refresh from '@splunk/react-icons/Refresh';

import Error from './Error';

const errorBarStyle = { display: 'flex', flexDirection: 'row', margin: '16px auto', maxWidth: 800 };
const growStyle = {
    flexGrow: 1,
};

const buttonStyle = { flexGrow: 0, padding: '6px 0 0 6px' };

const ErrorBar = ({ error, onRetry }) => {
    if (!error) {
        return null;
    }
    return (
        <div style={errorBarStyle}>
            <div style={growStyle}>
                <Error large error={error} />
            </div>
            {onRetry ? (
                <div style={buttonStyle}>
                    <Button appearance="pill" icon={<Refresh />} onClick={onRetry} />
                </div>
            ) : null}
        </div>
    );
};

ErrorBar.propTypes = {
    onRetry: T.func,
    error: T.object,
};

export default ErrorBar;
