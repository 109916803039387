import ResourceContainerBase from './ResourceContainerBase';

class IngestBase extends ResourceContainerBase {
    subTabs() {
        return [
            { label: 'Pipelines', resourceType: 'pipelines' },
            { label: 'Tokens', resourceType: 'tokens' },
        ];
    }
}

export default IngestBase;
