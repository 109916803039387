import React, { Component } from 'react';
import T from 'prop-types';
import Button from '@splunk/react-ui/Button';
import Text from '@splunk/react-ui/Text';
import Modal from '@splunk/react-ui/Modal';

import P from '@splunk/react-ui/Paragraph';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

import Error from './Error';

const createPayload = (params) => {
    const obj = params.reduce((agg, param) => {
        // eslint-disable-next-line no-param-reassign
        agg[param] = '';
        return agg;
    }, {});
    return JSON.stringify(obj, undefined, 2);
};

class TriggerDialog extends Component {
    constructor(...args) {
        super(...args);

        this.state = { payload: createPayload(this.props.params) };
    }

    handlePayloadChange = (e, { value }) => {
        this.setState({ payload: value });
    };

    render() {
        const { onCancel, onConfirm, error, saving, name } = this.props;
        return (
            <Modal onRequestClose={onCancel} open style={{ width: '600px' }}>
                <Modal.Header title={`Trigger ${name}`} />
                <Modal.Body>
                    <Error error={error} />
                    <P>{`Enter a payload for the "${name}" action.`}</P>
                    <Text onChange={this.handlePayloadChange} value={this.state.payload} multiline />
                </Modal.Body>
                <Modal.Footer>
                    {saving ? <WaitSpinner style={{ float: 'left' }} /> : null}
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                    />
                    <Button
                        disabled={saving}
                        appearance="secondary"
                        onClick={(e) => onConfirm(e, { payload: this.state.payload })}
                        label="Trigger"
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

TriggerDialog.propTypes = {
    saving: T.bool.isRequired,

    name: T.string.isRequired,
    params: T.arrayOf(T.string).isRequired,

    error: T.object,
    onConfirm: T.func.isRequired,
    onCancel: T.func.isRequired,
};

export default TriggerDialog;
