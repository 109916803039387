import React from 'react';
import DatasetsBase from './DatasetsBase';

import CreateDatasetDialog from '../components/CreateDatasetDialog';

class ViewsContainer extends DatasetsBase {
    static PATH = '/:tenantId/datasets/';
    title = 'Views';

    getResource = (tenantId) => this.props.splunkCloud.catalog.listDatasets({ filter: 'kind="view"' });

    createResource = (tenantId, resourceId, data) => {        
        const search = data.search || '| from []';
        return this.props.splunkCloud.catalog.createDataset({ ...data, search })
    };

    renderResource() {
        return this.renderDatasetsList();
    }

    renderCreateDialog() {
        return (
            <CreateDatasetDialog
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={this.state.resource.map((dataset) => dataset.module)}
                kind="view"
            />
        );
    }
}

export default ViewsContainer;
