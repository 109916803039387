import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Markdown from '@splunk/react-ui/Markdown';

import changelog from '../../CHANGELOG.md';

const StyledMarkdown = styled(Markdown)`
    h1 {
        font-size: 24px;        
        margin: 0;
    }

    // Make the first heading (Change Log) a little larger
    h1:first-child {
        font-size: 28px;
    }

    h2 {
        font-size: 16px;        
        margin: 0;
    }
`;

const isLineWanted = line => {
    if (line.startsWith('<a name=')) {
        return false;
    }

    if (line.startsWith('All notable changes')){
        return false;
    }

    if (line.startsWith('See [Conventional Commits]')){
        return false;
    }

    return true;
}

export default ({className}) => {
    
    const [changes, setChanges] = useState('');

    const setError = (error) => {
        setChanges([
            '# Change Log',
            'Unable to load changelog:',
            `> ${error.message}`
        ].join('\n'));
    }

    useEffect(() => {
        fetch(changelog).then((response)  => {            
            response.text().then(raw => {
                const lines = raw.split('\n');
                const clean = lines.filter(isLineWanted);
                setChanges(clean.join('\n'))
            }, setError);
        }, setError);        
    }, []);

    return (
        <StyledMarkdown text={changes} className={className} />
    );
};
