import React, { Component } from 'react';
import T from 'prop-types';

import Button from '@splunk/react-ui/Button';

const defaultState = {
    open: false,
    saving: false,
    error: null,
};
class DialogActionButton extends Component {
    constructor(...args) {
        super(...args);

        this.state = defaultState;
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleAction = (e, data) => {
        if (data) {
            this.setState({ saving: true });
            try {
                this.props.action(data).then(
                    (result) => {
                        this.setState(defaultState);
                        if (this.props.onSuccess) {
                            this.props.onSuccess(result);
                        }
                    },
                    (error) => this.setState({ error, saving: false })
                );
            } catch (error) {
                this.setState({ error, saving: false });
            }
        } else {
            this.setState(defaultState);
        }
    };

    render() {
        const { children, label, primary } = this.props;
        const { open, error, saving } = this.state;
        return [
            <Button
                key={`${label}-button`}                
                onClick={this.handleOpen}
                inline
                label={label}
                appearance={primary ? 'primary' : 'secondary'}
            />,
            open ? children({ handleAction: this.handleAction, error, saving }) : null,
        ];
    }
}

DialogActionButton.propTypes = {
    label: T.string.isRequired,
    children: T.func.isRequired,
    action: T.func.isRequired,
    primary: T.bool,
    onSuccess: T.func,
};

export default DialogActionButton;
