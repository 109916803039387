import React from 'react';

import Collection from '../components/Collection';
import CreateCollectionDialog from '../components/CreateCollectionDialog';

import DatasetsBase from './DatasetsBase';

class CollectionsContainer extends DatasetsBase {
    static PATH = '/:tenantId/collections/';
    title = 'Collections';

    getResource = (tenantId) =>
        this.props.splunkCloud.catalog
            .listDatasets({ filter: 'kind="kvcollection"' })
            .then((datasets) => datasets.map((dataset) => ({ id: dataset.resourcename })));

    createResource = (tenantId, resourceId, data) => {
        const collection = {
            kind: 'kvcollection',
            name: data.lookup ? `${data.name}_collection` : data.name,
            module: data.module,
        };
        return this.props.splunkCloud.catalog.createDataset(collection).then((response) => {
            if (data.lookup) {
                const lookup = {
                    name: data.name,
                    kind: 'lookup',
                    externalKind: 'kvcollection',
                    externalName: collection.name,
                    module: collection.module,
                };
                return this.props.splunkCloud.catalog.createDataset(lookup);
            }
            return response;
        });
    };

    renderResource() {
        return (
            <Collection
                resourceType={this.props.resourceType}
                basePath={`/${this.props.tenantId}/collections/`}
                resources={this.state.resource}
                history={this.props.history}
            />
        );
    }

    renderCreateDialog() {
        return (
            <CreateCollectionDialog
                open={this.state.createDialogOpen}
                title="Create Collection"
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={[]}
            />
        );
    }
}

export default CollectionsContainer;
