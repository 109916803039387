import React from 'react';
import DatasetsBase from './DatasetsBase';

import CreateDatasetDialog from '../components/CreateDatasetDialog';

import Datasets from '../components/Datasets';

class ModulesContainer extends DatasetsBase {
    static PATH = '/:tenantId/datasets/';
    title = 'Modules';

    getResource = (tenantId) =>
        {
            return this.props.splunkCloud.client
                .get('api', `/${tenantId}/search/v3alpha1/spl2-modules`).then((response) => {
                    const results = response?.body?.results || [];
                    return results.map(({name, createdBy, namespace}) => ({name, module: namespace, owner: createdBy, resourcename: namespace ? `${namespace}.${name}`: name}));
                });
        };

    createResource = (tenantId, resourceId, data) => {        
        
        const { module: namespace, name, search: definition} = data;
        console.log({name, namespace, definition});

        const resourceName = namespace ? `${namespace}.${name}` : name;

        return this.props.splunkCloud.client
            .put('api', `/${tenantId}/search/v3alpha1/spl2-modules/${resourceName}`, {
                name, namespace, definition
            });
    };

    renderResource() {
        return (<Datasets
                basePath={`/${this.props.tenantId}/modules/`}
                datasets={this.state.resource}
                history={this.props.history}
                showKindColumn={false}
        />);
    }

     renderCreateDialog() {
        return (
            <CreateDatasetDialog
                error={this.state.dialogError}
                onCreate={this.handleCreate}
                onCancel={this.handleCreate}
                saving={this.state.saving}
                modules={this.state.resource.map((dataset) => dataset.module)}
                kind="module"
            />
        );
    }
}

export default ModulesContainer;
