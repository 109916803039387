import React from 'react';

import Button from '@splunk/react-ui/Button';

import { search } from '../links';
import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import ConfirmationDialog from '../components/ConfirmationDialog';
import DialogActionButton from '../components/DialogActionButton';
import CreateCollectionDialog from '../components/CreateCollectionDialog';
import CreateFieldDialogActionButton from '../components/CreateFieldDialog/ActionButton';
import Fields from '../components/Fields';

import DatasetsBase from './DatasetsBase';
import Metadata from '../components/Metadata';
import EditSearch from './EditSearch';

const needsQuote = /^[a-zA-Z0-9_]+$/;
export const quoteField = (field) => (needsQuote.test(field) ? field : `'${field}'`);

const splForDataset = (dataset) => {
    switch (dataset.kind) {
        case 'metric':
            return `| mcatalog values(metric_name) as metrics where index=${quoteField(dataset.name)}`;
        case 'job':
            return dataset.query;
        case 'index':
            return `| from ${quoteField(dataset.name)} | head limit=100`;
        default:
            return null;
    }
};


class DatasetContainer extends DatasetsBase {
    static PATH = '/:tenantId/datasets/:resourceId/';
    getResource = (tenantId, datasetId) => {
        const datasetPromise = this.props.splunkCloud.catalog.getDataset(datasetId);

        this.props.splunkCloud.catalog.listFieldsForDataset(datasetId).then((fields) => {
            this.setState((prev) => {
                return { ...prev, fields };
            });
        }, console.error);

        return datasetPromise;
    };

    deleteResource = () => this.props.splunkCloud.catalog.deleteDataset(this.props.resourceId);

    exportDataset = (data) => {
        const dataset = {
            kind: 'import',
            sourceModule: this.state.resource.module,
            sourceName: this.state.resource.name,
            module: data.module,
            name: data.name,
        };
        return this.props.splunkCloud.catalog.createDataset(dataset);
    };

    renderActions() {
        const spl = this.state.resource ? splForDataset(this.state.resource) : null;
        const actions = [
            <ConfirmationDialogActionButton
                key="delete"
                label="Delete"
                title={`Delete ${this.props.resourceId}`}
                message={`Delete the "${this.props.resourceId}" dataset?`}
                handleConfirmedAction={this.deleteResource}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}`)
                }
            />,
        ];

        if (spl) {
            actions.push(
                <Button
                    openInNewContext
                    appearance="secondary"
                    key="search"                    
                    to={search({ tenantId: this.props.tenantId, earliest: '-24h', spl })}
                    label="Search"
                />
            );
        }

        if (this.state.resource) {
            actions.push(
                <DialogActionButton key="export" label="Export" action={this.exportDataset}>
                    {({ handleAction, error, saving }) => (
                        <CreateCollectionDialog
                            title={`Export ${this.state.resource.name}`}
                            key="export-dialog"
                            onCreate={handleAction}
                            onCancel={handleAction}
                            error={error}
                            saving={saving}
                        />
                    )}
                </DialogActionButton>
            );

            actions.push(
                <CreateFieldDialogActionButton
                    key="create-field"
                    onSuccess={(newField) => {
                        this.setState((prev) => ({ ...prev, fields: [newField, ...prev.fields] }));
                    }}
                    handleCreateFieldAction={(data) => {
                        console.log('handleCreateFieldAction', data);
                        return this.props.splunkCloud.catalog.createFieldForDataset(
                            this.props.resourceId,
                            data.field
                        );
                    }}
                />
            );
        }

        return actions;
    }

    handleDeleteField = (e, field) => {
        this.setState((prev) => ({ ...prev, fieldToDelete: field }));
    };

    handleDeleteFieldConfirmation = (e, data) => {
        if (data && data.confirmed) {
            const { fieldToDelete } = this.state;
            if (fieldToDelete) {
                this.props.splunkCloud.catalog.deleteFieldByIdForDataset(
                    this.props.resourceId,
                    fieldToDelete.id
                );
                const fields = this.state.fields.filter((field) => field.id !== fieldToDelete.id);
                this.setState((prev) => ({ ...prev, fields, fieldToDelete: null }));
                return;
            }
        }

        this.setState((prev) => ({ ...prev, fieldToDelete: null }));
    };

    handleUpdateField = (e, field, changes) => {
        this.props.splunkCloud.catalog
            .updateFieldByIdForDataset(this.props.resourceId, field.id, changes)
            .then(() => {
                const fields = this.state.fields.map((f) => {
                    if (field.id === f.id) {
                        return { ...f, ...changes };
                    }
                    return field;
                });

                this.setState((prev) => ({ ...prev, fields }));
            }, console.error);
    };

    handleUpdateSearch = (search) => {
        this.props.splunkCloud.catalog.updateDataset(this.state.resource.resourcename, { search }).then(
            this.setState(prev => ({...prev, resource: {...prev.resource, search }}))
        );
    };

    renderResource() {
        return (
            <>
                <Metadata data={this.state.resource} />                
                {this.state.resource.kind === 'view' &&
                <EditSearch title="SPL" search={this.state.resource.search} onUpdateSearch={this.handleUpdateSearch} />}
                <Fields
                    fields={this.state.fields}
                    onDeleteField={this.handleDeleteField}
                    onUpdateField={this.handleUpdateField}
                />
                
                {this.state.fieldToDelete && (
                    <ConfirmationDialog
                        saving={false}
                        title="Delete Field"
                        message={`Are you sure you want to delete the field "${this.state.fieldToDelete.name}"?`}
                        action="Delete"
                        onConfirm={this.handleDeleteFieldConfirmation}
                        onCancel={this.handleDeleteFieldConfirmation}
                    />
                )}
            </>
        );
    }
}

export default DatasetContainer;
