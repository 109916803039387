import React from 'react';
import T from 'prop-types';

import Heading from '@splunk/react-ui/Heading';
import P from '@splunk/react-ui/Paragraph';
import Shuffle from './Shuffle';

const User = ({ user, groups, allGroups, onAdd, onRemove }) => (
    <section style={{ margin: 16 }}>
        <section>
            <Heading level={3}>Groups</Heading>
            <P>
                Toggle the groups that the <b>{user}</b> is a member of.
            </P>
            <Shuffle set={groups} superset={allGroups} onAdd={onAdd} onRemove={onRemove} />
        </section>
    </section>
);

User.propTypes = {
    user: T.string.isRequired,
    groups: T.arrayOf(T.string).isRequired,
    allGroups: T.arrayOf(T.string).isRequired,
    onAdd: T.func.isRequired,
    onRemove: T.func.isRequired,
};

export default User;
