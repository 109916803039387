import React from 'react';

import ConfirmationDialogActionButton from '../components/ConfirmationDialogActionButton';
import User from '../components/User';

import PermissionsBase from './PermissionsBase';

const nonIdentityGroup = (group) => !group.startsWith('identity.');

class UserContainer extends PermissionsBase {
    static PATH = '/:tenantId/users/:resourceId/';
    getResource = (tenantId, userId) => {
        const client = this.props.splunkCloud;
        return Promise.all([
            client.identity.getMember(userId),
            client.identity.listMemberGroups(userId),
            client.identity.listGroups(),
        ]).then(([member, groups, allGroups]) => ({
            ...member,
            groups: groups.filter(nonIdentityGroup),
            allGroups: allGroups.filter(nonIdentityGroup),
        }));
    };

    deleteResource = () => this.props.splunkCloud.identity.removeMember(this.props.resourceId);

    handleAdd = (values) => {
        const name = this.props.resourceId;
        values.forEach((groupName) =>
            this.props.splunkCloud.identity.addGroupMember(groupName, { name }).catch(this.handleError)
        );
        this.setState({
            resource: {
                ...this.state.resource,
                groups: [...this.state.resource.groups, ...values],
            },
        });
    };

    handleRemove = (values) => {
        const name = this.props.resourceId;
        values.forEach((groupName) =>
            this.props.splunkCloud.identity.removeGroupMember(groupName, name).catch(this.handleError)
        );

        const groupsSet = new Set(values);
        this.setState({
            resource: {
                ...this.state.resource,
                groups: this.state.resource.groups.filter((group) => !groupsSet.has(group)),
            },
        });
    };

    renderActions() {
        return (
            <ConfirmationDialogActionButton
                label="Remove User"
                title={`Remove ${this.props.resourceId}`}
                message={`Remove the user ${this.props.resourceId}?`}
                handleConfirmedAction={this.deleteResource}
                onSuccess={() =>
                    this.props.history.push(`/${this.props.tenantId}/${this.props.resourceType}`)
                }
            />
        );
    }

    renderResource = (data) => (
        <User
            user={data.name}
            groups={data.groups}
            allGroups={data.allGroups}
            onAdd={this.handleAdd}
            onRemove={this.handleRemove}
        />
    );
}

export default UserContainer;
