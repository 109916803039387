import React, { Component } from 'react';
import T from 'prop-types';

import DL from '@splunk/react-ui/DefinitionList';
import Table from '@splunk/react-ui/Table';

import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const columnsWithKind = [
    { sortKey: 'name', label: 'name' },
    { sortKey: 'module', label: 'namespace' },
    { sortKey: 'kind', label: 'kind' },
    { sortKey: 'owner', label: 'owner' },
];

const columnsWithoutKind = [
    { sortKey: 'name', label: 'name' },
    { sortKey: 'module', label: 'namespace' },
    { sortKey: 'owner', label: 'owner' },
];

const handleClick = (history, to) => event => {
    event.preventDefault();
    if (event.metaKey || event.ctrlKey) {
        window.open(to, '_blank');
    } else {
        history.push(to);
    }
};

const formatTime = timeString => {
    if (timeString) {
        const date = new Date(timeString);
        return `${date.toDateString()} ${date.toTimeString()}`;
    }
    return '';
};

const numberFormat = new Intl.NumberFormat();
const formatNumber = number => {
    if (number || number === 0) {
        return numberFormat.format(number);
    }
    return '';
};
const expansionRow = row => {
    if (row.kind !== 'index' && row.kind !== 'metric') {
        return undefined;
    }

    return (
        <tr>
            <td colSpan={4}>
                <DL termWidth={150}>
                    <DL.Term>Latest Ingest Time</DL.Term>
                    <DL.Description>{formatTime(row.latestIngestTime)}</DL.Description>
                    <DL.Term>Latest Event Time</DL.Term>
                    <DL.Description>{formatTime(row.latestEventTime)}</DL.Description>
                    <DL.Term>Total Event Count</DL.Term>
                    <DL.Description>{formatNumber(row.totalEventCount)}</DL.Description>
                </DL>
            </td>
        </tr>
    );
};
class Datasets extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            sortKey: 'name',
            sortDir: 'asc',
        };
    }

    handleSort = (e, { sortKey }) => {
        const prevSortKey = this.state.sortKey;
        const prevSortDir = prevSortKey === sortKey ? this.state.sortDir : 'none';
        const nextSortDir = prevSortDir === 'asc' ? 'desc' : 'asc';
        this.setState({
            sortKey,
            sortDir: nextSortDir,
        });
    };

    render() {
        const { datasets, basePath, history } = this.props;
        const { sortKey, sortDir } = this.state;
        if (!datasets) {
            return <WaitSpinner size="medium" />;
        }
        const columns = this.props.showKindColumn ? columnsWithKind : columnsWithoutKind;
        return (
            <Table key="datasets" rowExpansion="single">
                <Table.Head>
                    {columns.map(headData => (
                        <Table.HeadCell
                            key={headData.sortKey}
                            onSort={this.handleSort}
                            sortKey={headData.sortKey}
                            sortDir={headData.sortKey === sortKey ? sortDir : 'none'}
                        >
                            {headData.label}
                        </Table.HeadCell>
                    ))}
                </Table.Head>
                <Table.Body>
                    {datasets
                        .sort((rowA, rowB) => {
                            if (sortDir === 'asc') {
                                return rowA[sortKey] > rowB[sortKey] ? 1 : -1;
                            } else if (sortDir === 'desc') {
                                return rowB[sortKey] > rowA[sortKey] ? 1 : -1;
                            }
                            return 0;
                        })
                        .map(row => (
                            <Table.Row key={row.id} expansionRow={expansionRow(row)}>
                                <Table.Cell onClick={handleClick(history, `${basePath}${row.resourcename}/`)}>
                                    {row.name}
                                </Table.Cell>
                                <Table.Cell>{row.module}</Table.Cell>
                                {this.props.showKindColumn && <Table.Cell>{row.kind}</Table.Cell>}
                                <Table.Cell>{row.owner}</Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        );
    }
}

Datasets.propTypes = {
    basePath: T.string.isRequired,
    datasets: T.array,
    history: T.object,
};

export default Datasets;
