import React from 'react';
import T from 'prop-types';

import Heading from '@splunk/react-ui/Heading';
import P from '@splunk/react-ui/Paragraph';

import Shuffle from './Shuffle';

const Group = ({
    group,
    roles,
    allRoles,    
    members,
    allUsers,
    onAddRoles,
    onRemoveRoles,
    onAddMembers,
    onRemoveMembers
}) => (
    <div style={{ margin: 16 }}>
        <section>
            <Heading level={3}>Roles</Heading>
            <P>
                Toggle the roles that the <b>{group}</b> group assumes.
            </P>
            <Shuffle size={10} set={roles} superset={allRoles} onAdd={onAddRoles} onRemove={onRemoveRoles}/>            
        </section>
        <section>
            <Heading level={3}>Members</Heading>
            <P>
                Toggle users&apos; membership of the <b>{group}</b> group.
            </P>
            <Shuffle size={10} set={members} superset={allUsers} onAdd={onAddMembers} onRemove={onRemoveMembers} />
        </section>
    </div>
);

Group.propTypes = {
    group: T.string.isRequired,
    roles: T.arrayOf(T.string).isRequired,
    allRoles: T.arrayOf(T.string).isRequired,
    members: T.arrayOf(T.string).isRequired,
    allUsers: T.arrayOf(T.string).isRequired,
    onAddRoles: T.func.isRequired,
    onRemoveRoles: T.func.isRequired,
    onAddMembers: T.func.isRequired,
    onRemoveMembers: T.func.isRequired,
};

export default Group;
