import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const render = () => {
    const el = document.getElementById('root');
    ReactDOM.unmountComponentAtNode(el);
    ReactDOM.render(React.createElement(App, { basePath: '/' }), el);
};

render();
