import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Message from '@splunk/react-ui/Message';
import { GrantType, SplunkAuthClient, SplunkAuthClientSettings } from '@splunkdev/cloud-auth-client';
import { SplunkCloud } from '@splunkdev/cloud-sdk';

import { AuthContext } from './AuthContext';

const MessageContainer = styled.div`
    max-width: 800px;
    margin: 40px auto;
`;

export const createAuthClient = (config, tenantName) => {
    return new Promise((resolve, reject) => {
        let resolved = false;
        let tokenSource;
        const authClientSettings = new SplunkAuthClientSettings(
            GrantType.PKCE,
            config.clientId,
            window.location.origin,
            (path) => {
                if (!resolved) {
                    resolved = true;
                    resolve({ tokenSource, path });
                }
            },
            `${config.authUrl}/authorize`,
            true, // autoRedirectToLogin
            true, // restorePathAfterLogin
            600, // maxClockSkew
            {}, // queryParamsForLogin
            120, // autoTokenRenewalBuffer
            'splunk-token-storage', // tokenStorageName
            'splunk-redirect-params-storage', // redirectParamsStorageName
            !!tenantName, // enableTenantScopedTokens
            true // enableMultiRegionSupport  
        );

        try {
            tokenSource = new SplunkAuthClient(authClientSettings, tenantName);

            if (tokenSource.isAuthenticated()) {
                resolved = true;
                resolve({ tokenSource });
            }
        } catch (error) {
            resolved = true;
            reject(error);   
        }
    });
};

const authenticate = async (history, config) => {
    try {
        const initialPath = window.location.pathname.split('/');
        const tenantName = initialPath.length > 1 ? initialPath[1] : undefined;
        const { tokenSource, path } = await createAuthClient(config, tenantName);
        if (path) {
            history.replace(path);
        }

        const api = tenantName ? `https://${tenantName}.${config.apiEndpoint.substring(8)}`: config.apiEndpoint;
        const app = config.appEndpoint;
        
        const splunkCloud = new SplunkCloud({
            urls: { api, app },
            tokenSource,
            defaultTenant: tenantName || 'system',
        });

        try {
            const info = await splunkCloud.identity.validateToken();
            
            return { api, app, tokenSource, user: info.name };
        }  catch (error) {
            tokenSource && tokenSource.logout && tokenSource.logout();
            throw error;
        }
        
    } catch (error) {        
        return { error };
    }
};

const Auth = ({ history, config, children }) => {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        let ignore = false;
        const maybeSetAuth = (state) => {
            if (!ignore) {
                setAuth(state);
            }
        };

        authenticate(history, config).then(maybeSetAuth, maybeSetAuth);

        return () => {
            ignore = true;
        };
    }, [history, config]);

    if (!auth) {
        return null;
    }

    if (auth.error) {
        return (
            <MessageContainer>
                <Message appearance="fill" type="error" onRequestRemove={() => window.location.reload()}>
                    {`Unable to authenticate: ${auth.error.message}`}
                </Message>
            </MessageContainer>
        );
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default Auth;
