import React from 'react';
import T from 'prop-types';

import ConfirmationDialog from './ConfirmationDialog';
import DialogActionButton from './DialogActionButton';

const ConfirmationDialogActionButton = ({
    handleConfirmedAction,
    label,
    message,
    onSuccess,
    primary,
    title,
}) => (
    <DialogActionButton
        key={label}
        primary={primary}
        label={label}
        action={handleConfirmedAction}
        onSuccess={onSuccess}
    >
        {({ handleAction, error, saving }) => (
            <ConfirmationDialog
                key="ConfirmationDialog"
                saving={saving}
                action={label}
                message={message}
                title={title}
                error={error}
                onConfirm={handleAction}
                onCancel={handleAction}
            />
        )}
    </DialogActionButton>
);

ConfirmationDialogActionButton.propTypes = {
    handleConfirmedAction: T.func.isRequired,
    label: T.string.isRequired,
    message: T.string.isRequired,
    onSuccess: T.func,
    title: T.string.isRequired,
    primary: T.bool,
};

export default ConfirmationDialogActionButton;
