import React, { useMemo } from 'react';

import Select from '@splunk/react-ui/Select';
import Table from '@splunk/react-ui/Table';
import Tooltip from '@splunk/react-ui/Tooltip';
import Button from '@splunk/react-ui/Button';
import Remove from '@splunk/react-icons/Remove';

export const FieldType = ({ disabled, fieldtype, onChange }) => (
    <Select disabled={disabled} name="fieldtype" value={fieldtype} onChange={onChange}>
        <Select.Option label="Dimension" value="DIMENSION" />
        <Select.Option label="Measure" value="MEASURE" />
        <Select.Option label="Unknown" value="UNKNOWN" />
    </Select>
);

export const DataType = ({ disabled, datatype, onChange }) => (
    <Select disabled={disabled} name="datatype" value={datatype} onChange={onChange}>
        <Select.Option label="Date" value="DATE" />
        <Select.Option label="Number" value="NUMBER" />
        <Select.Option label="Object ID" value="OBJECT_ID" />
        <Select.Option label="String" value="STRING" />
        <Select.Option label="Unknown" value="UNKNOWN" />
    </Select>
);

const RemoveButton = ({ disabled, onClick }) => (
    <Tooltip content="Delete" onClick={onClick} style={{ marginRight: 8 }}>
        <Button
            disabled={disabled}
            aria-label="Delete"
            appearance="secondary"
            icon={<Remove screenReaderText={null} size={1} />}
        />
    </Tooltip>
);

const Fields = ({ fields, onDeleteField, onUpdateField }) => {
    const rows = useMemo(() => {
        if (!fields) {
            return null;
        }
        return fields.map((field) => (
            <Table.Row
                key={field.name}
                actionsPrimary={
                    <RemoveButton disabled={!onDeleteField} onClick={(e) => onDeleteField(e, field)} />
                }
            >
                <Table.Cell>{field.name}</Table.Cell>
                <Table.Cell>
                    <DataType
                        disabled={!onUpdateField}
                        datatype={field.datatype}
                        onChange={(e, data) => onUpdateField(e, field, { [data.name]: data.value })}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FieldType
                        disabled={!onUpdateField}
                        fieldtype={field.fieldtype}
                        onChange={(e, data) => onUpdateField(e, field, { [data.name]: data.value })}
                    />
                </Table.Cell>
            </Table.Row>
        ));
    }, [fields, onDeleteField, onUpdateField]);

    return (
        <Table actionsColumnWidth={50}>
            <Table.Head>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell>Data Type</Table.HeadCell>
                <Table.HeadCell>Field Role</Table.HeadCell>
            </Table.Head>
            <Table.Body>{rows}</Table.Body>
        </Table>
    );
};

export default Fields;
