import React from 'react';
import styled from 'styled-components';

import { SplunkThemeProvider, mixins, variables } from '@splunk/themes';

import { version } from '../package.json';

const Container = styled.div`
    ${mixins.reset('block')}
    background-color: ${variables.backgroundColorPage};
    height: 100vh;
`;

const Theme = ({ children }) => (
    <SplunkThemeProvider family="prisma" colorScheme="dark" density="comfortable">
        <Container data-test-version={version}>{children}</Container>
    </SplunkThemeProvider>
);

export default Theme;
