import React, { useRef } from 'react';
import T from 'prop-types';
import styled from 'styled-components';

import { variables } from '@splunk/themes';

const ShuffleLayout = styled.div`
    display: flex;

    & > select {
        flex: 1 1 50%;
        min-width: 0;
        background-color: ${variables.interactiveColorBackground};
        color: ${variables.contentColorDefault};
        border: 4px solid ${variables.backgroundColorSection};
        border-radius: 2px;
        font-size: ${variables.fontSize};
    }

    & > div {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        padding: 8px;
    }
`;

const getSelectedOptions = (select) => {
    const values = [];
    const { selectedOptions } = select;
    for (let i=0; i<selectedOptions.length; i += 1) {
        values.push(selectedOptions[i].value);
    }
    return values;
};

const Shuffle = ({ set, superset, onAdd, onRemove, size=15 }) => {
    const members = new Set(set);
    const nonMembers = superset.filter(member => !members.has(member));
    const unselectedRef = useRef();
    const selectedRef = useRef();

    const handleAdd = () => {
        const values = getSelectedOptions(unselectedRef.current);
        if (values.length) {            
            onAdd(values);
        }        
    }

    const handleRemove = () => {
        const values = getSelectedOptions(selectedRef.current);
        if (values.length) {            
            onRemove(values);
        }
    };

    return (        
         <ShuffleLayout>
            <select ref={unselectedRef} size={size} multiple="multiple" >
                {nonMembers.map(value => (<option key={value} value={value}>{value}</option>))}
            </select>
            <div>
                <button type="button" onClick={handleAdd}>&gt;</button>
                <button type="button" onClick={handleRemove}>&lt;</button>            
            </div>
            <select ref={selectedRef} size={size} multiple="multiple" >
                {set.map(value => (<option key={value} value={value}>{value}</option>))}
            </select>
        </ShuffleLayout>
    );
};

Shuffle.propTypes = {
    set: T.arrayOf(T.string).isRequired,
    superset: T.arrayOf(T.string).isRequired,
    onAdd: T.func.isRequired,
    onRemove: T.func.isRequired,
    size: T.number,
};

export default Shuffle;
