import React, { Component } from 'react';
import T from 'prop-types';
import Button from '@splunk/react-ui/Button';

import Modal from '@splunk/react-ui/Modal';

import Error from '../Error';

import Create from './ActionForm';

const propTypes = {
    saving: T.bool.isRequired,
    error: T.object,
    onCreate: T.func.isRequired,
    onCancel: T.func.isRequired,
    title: T.string.isRequired,
};

class ActionModal extends Component {
    static clearFormState = {
        name: '',
        kind: 'webhook',
    };

    constructor(...args) {
        super(...args);
        this.state = { form: ActionModal.clearFormState };
    }

    handleFormChange = (e, { name, value }) => {
        this.setState({ form: { ...this.state.form, [name]: value } });
    };

    handleSubmit = (e) => {
        this.props.onCreate(e, { ...this.state.form });
    };

    render() {
        const { submitStatus, title, onCancel, saving, error } = this.props;
        const { form } = this.state;
        return (
            <Modal open style={{ width: 500 }}>
                <Modal.Header title={title} />
                <Modal.Body>
                    <Error error={error} />
                    <Create
                        onChange={this.handleFormChange}
                        onSubmit={this.handleSubmit}
                        {...form}
                        {...submitStatus}
                        modules={[]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        appearance="secondary"
                        disabled={saving}
                        onClick={(e) => onCancel(e)}
                        label="Cancel"
                        key="cancel"
                    />
                    <Button
                        appearance="primary"
                        disabled={saving || !this.state.form.name}
                        onClick={this.handleSubmit}
                        label="Create"
                        key="create"
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

ActionModal.propTypes = propTypes;
export default ActionModal;
